import React, { useEffect, useState } from 'react'
import styles from './DayDetail.module.css'
import { Header } from '../../components/Header/Header'
import { toast } from 'react-toastify'

export const DayDetail = () => {
    
    return (
        <div>
            <Header/>
            <div>

            </div>
        </div>
    )
}
