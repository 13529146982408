import React from "react";
import Barcode from "react-barcode";
import styles from './PrintBarcode.module.css'

const PrintBarcode = React.forwardRef(({ name, price, barcode }, ref) => {
    return (
        <div ref={ref} className={styles.printBarcode}>
            <div
                style={{
                    width: "30mm",
                    height: "20mm",
                    padding: "3px",
                    border: "1px solid black",
                    fontSize: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div style={{ fontWeight: "bold", textAlign: "center", fontSize: "9px" }}>
                    {name}
                </div>
                <div style={{ fontSize: "10px", fontWeight: "bold" }}>Цена: {Math.floor(price)}с</div>
                <Barcode value={barcode.toString()} width={0.8} height={30} fontSize={8} />
            </div>
        </div>
    );
});

export default PrintBarcode;
