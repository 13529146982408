import React, { useState, useEffect } from "react";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/');
        }
    }, [navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/login/`, {
                username,
                password,
            });

            localStorage.setItem("token", response.data.token);
            localStorage.setItem("shop_id", response.data.shop_membership.shop_id);
            localStorage.setItem("is_admin", response.data.shop_membership.is_admin);

            const is_admin = response.data.shop_membership.is_admin

            if (is_admin === 'false') {
                navigate('/non_administrator');
            } else {
                navigate("/");
            }
        } catch (error) {
            setError("Неправильный username или пароль. Попробуйте снова.");
        }
    };

    const toggleShowPassword = () => {
        setShowPassword((prev) => !prev);
    };


    return (
        <div>
            <div className={styles.login}>
                <div className={styles.login_block}>
                    <div className="">
                        <h3 className={styles.login__text}>
                            Вход
                        </h3>
                        <form className={styles.login__form} onSubmit={handleLogin}>
                            <div className={styles.login_input}>
                                <input
                                    className={styles.intLogin}
                                    type="text"
                                    placeholder="Username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className={styles.login_input}>
                                <input
                                    className={styles.intPassword}
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />

                                <button
                                    type="button"
                                    className={styles.toggle_prices_btn}
                                    onClick={toggleShowPassword}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>


                            {error && <p className={styles.error}>{error}</p>}
                            <div>
                                <button type="submit" className={styles.loginBtn}>
                                    Войти
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
