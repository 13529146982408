import React from 'react'
import styles from './Kassa.module.css'
import { Header } from '../../components/Header/Header'

export const Kassa = () => {
  return (
    <div>
        <Header/>
        <div>
            
        </div>
    </div>
  )
}
