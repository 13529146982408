import React, { useState, useEffect } from 'react';
import styles from './PersonDetail.module.css';
import axios from 'axios';
import { Header } from '../../components/Header/Header';
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';

const formatDate = (dateStr) => {
    return moment(dateStr).format('DD.MM.YYYY, HH:mm');
};

export const PersonDetail = () => {
    const { id } = useParams();
    const token = localStorage.getItem("token");
    const [person, setPerson] = useState({});
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [debts, setDebts] = useState([]);
    const [amountDebt, setAmountDebt] = useState(0)
    const [selectedTransactionType, setTransactionType] = useState("Все");
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startDateDebt, setStartDateDebt] = useState('');
    const [endDateDebt, setEndDateDebt] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDateIsOpen, setModalDateIsOpen] = useState(false);
    const [isModalDateIsOpenDebt, setModalDateIsOpenDebt] = useState(false);
    const [isModalDebtMethod_r, setModalDebtMethod_r] = useState(false)
    const [isModalDebtMethod_d, setModalDebtMethod_d] = useState(false)
    const [isLoading, setIsLoading] = useState(true);

    const [isModalDebtDelete, setModalDebtDelete] = useState(false);
    const [debtToDelete, setDebtToDelete] = useState(null);

    const [amount, setAmount] = useState('');
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [cash, setCash] = useState(0);
    const [nonCash, setNonCash] = useState(0);
    const [selectedView, setSelectedView] = useState("debt");


    const handleViewChange = (event) => {
        setSelectedView(event.target.value);
    };


    const fetchPersonDetail = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/persons/${id}/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            const personData = response.data;
            setPerson(personData);
            setName(personData.name);
            setPhone(personData.phone);
            setAmountDebt(personData.debt)
            setTimeout(() => {
                setIsLoading(false);
            }, 800);

        } catch (error) {
            console.error("Error fetching product details:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            } else {
                toast.error("Ошибка при загрузке деталей продукта.");
            }
        }
    };

    const fetchPersonTransactions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/transactions/?person=${id}`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setTransactions(response.data);
        } catch (error) {
            console.error("Error fetching transactions:", error);
            toast.error("Ошибка при загрузке транзакций.");
        }
    };

    const fetchPersonDebts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/debts/?person=${id}&ordering=-date`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setDebts(response.data);
        } catch (error) {
            console.error("Error fetching transactions:", error);
            toast.error("Ошибка при загрузке транзакций.");
        }
    };

    const deletePerson = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_MAIN_URL}/persons/${id}/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            toast.success("Продукт успешно удалён!");
            navigate('/customer');
        } catch (error) {
            console.error("Ошибка при удалении продукта:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            } else if (error.response && error.response.status === 404) {
                toast.error("Продукт не найден.");
            } else {
                toast.error("Ошибка при удалении продукта.");
            }
        }
    };

    const fetchDebtCreate_r = async () => {
        try {
            const debtData = {
                person: person.id,
                method: 'r',
                amount,
                date,
                cash,
                non_cash: nonCash
            };

            const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/debts/add/`, debtData, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            const createdDebt = response.data;
            toast.success('Debt успешно создан!');
            return createdDebt;
        } catch (error) {
            console.error('Ошибка при создании долга:', error);
            toast.error('Не удалось создать долг. Попробуйте еще раз.');
        }
    };

    const handleCreateDebt_r = async (e) => {
        e.preventDefault();

        const newDebt = await fetchDebtCreate_r();

        if (newDebt) {
            setDebts((prevDebts) => [...prevDebts, newDebt]);
            setAmount('');
            setCash(0);
            setNonCash(0);
            setDate('');

            closeModalMethdo_r();

            window.location.reload();
        }
    };

    const fetchDebtCreate_d = async () => {
        try {
            const debtData = {
                person: person.id,
                method: 'd',
                amount,
                date,
                cash,
                non_cash: nonCash
            };

            const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/debts/add/`, debtData, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            const createdDebt = response.data;
            toast.success('Debt успешно создан!');
            return createdDebt;
        } catch (error) {
            console.error('Ошибка при создании долга:', error);
            toast.error('Не удалось создать долг. Попробуйте еще раз.');
        }
    };

    const handleCreateDebt_d = async (e) => {
        e.preventDefault();

        const newDebt = await fetchDebtCreate_d();

        if (newDebt) {
            setDebts((prevDebts) => [...prevDebts, newDebt]);
            setAmount('');
            setCash(0);
            setNonCash(0);
            setDate('');

            closeModalMethdo_d();

            window.location.reload();
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const productData = {
                name,
                phone,
            };

            const response = await axios.put(`${process.env.REACT_APP_MAIN_URL}/persons/${id}/`, productData, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            setPerson(response.data);
            toast.success('Продукт успешно обновлен!');
        } catch (error) {
            console.error('Ошибка при обновлении продукта:', error);
            toast.error('Не удалось обновить продукт. Попробуйте еще раз.');
        }
    };

    const filteredTransactions = transactions.filter((transaction) => {
        const transactionDate = moment(transaction.date);
        const isAfterStartDate = startDate ? transactionDate.isSameOrAfter(moment(startDate), 'day') : true;
        const isBeforeEndDate = endDate ? transactionDate.isSameOrBefore(moment(endDate), 'day') : true;
        return isAfterStartDate && isBeforeEndDate && (selectedTransactionType === "Все" || transaction.transaction_type_display === selectedTransactionType);
    });

    const filteredDebts = debts.filter((debt) => {
        const debtDate = moment(debt.date);
        const isAfterStartDate = startDateDebt ? debtDate.isSameOrAfter(moment(startDateDebt), 'day') : true;
        const isBeforeEndDate = endDateDebt ? debtDate.isSameOrBefore(moment(endDateDebt), 'day') : true;
        return isAfterStartDate && isBeforeEndDate;
    });

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const openModal_date = () => setModalDateIsOpen(true);
    const closeModal_date = () => setModalDateIsOpen(false);

    const openModal_date_debt = () => setModalDateIsOpenDebt(true);
    const closeModal_date_debt = () => setModalDateIsOpenDebt(false);

    const openModalMethdo_r = () => setModalDebtMethod_r(true);
    const closeModalMethdo_r = () => setModalDebtMethod_r(false);


    const openModalMethdo_d = () => setModalDebtMethod_d(true);
    const closeModalMethdo_d = () => setModalDebtMethod_d(false);

    const openModalDebtDelete = (debtId) => {
        setDebtToDelete(debtId);
        setModalDebtDelete(true);
    };

    const closeModalDebtDelete = () => {
        setModalDebtDelete(false);
        setDebtToDelete(null);
    };

    const handleAmountChange = (e) => {
        const value = e.target.value === '' ? '' : parseFloat(e.target.value);
        setAmount(value);
        if (value === '') {
            setCash('');
        } else {
            setCash(value - (nonCash || 0));
        }
    };

    const handleCashChange = (e) => {
        const newCash = e.target.value === '' ? '' : parseFloat(e.target.value);
        setCash(newCash);
        if (newCash === '') {
            setNonCash(amount || '');
        } else {
            setNonCash((amount || 0) - newCash);
        }
    };


    const handleNonCashChange = (e) => {
        const value = e.target.value === '' ? '' : parseFloat(e.target.value);
        setNonCash(value);
        if (value === '') {
            setCash(amount || '');
        } else {
            setCash((amount || 0) - value);
        }
    };


    const deleteDebt = async (debtId) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_MAIN_URL}/debts/${debtId}/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            toast.success("Долг успешно удалён!");
            navigate(0);
        } catch (error) {
            console.error("Ошибка при удалении долга:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            } else if (error.response && error.response.status === 404) {
                toast.error("Долг не найден.");
            } else {
                toast.error("Ошибка при удалении долга.");
            }
        }
    };

    const handleDeleteConfirm = () => {
        if (debtToDelete) {
            deleteDebt(debtToDelete);
            closeModalDebtDelete();
        }
    };

    useEffect(() => {
        if (!token) {
            toast.error("Пожалуйста, войдите в систему");
            navigate('/login')
            return;
        }
        fetchPersonDetail()
        fetchPersonTransactions();
        fetchPersonDebts()
    }, [id, token,]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />

            <div className={styles.main_div_user}>
                <form onSubmit={handleUpdate} className={styles.updateProductForm}>
                    {person?.type_p === 's' ? (
                        <h2 className={styles.updateProductFormH2}>Поставщик</h2>
                    ) : (
                        <h2 className={styles.updateProductFormH2}>Покупатель</h2>
                    )}
                    <div className={styles.formGroup_name}>
                        <label htmlFor="personName">Имя</label>
                        <input
                            type="text"
                            id="personName"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>

                    <div className={styles.formGroup_number}>
                        <label htmlFor="number">Номер телефона</label>
                        <input
                            type="text"
                            id="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className={styles.cont_btns}>
                    <button type="submit" className={styles.submitButton}>Сохранить</button>
                    <button type="button" className={styles.submitButton_delete_update} onClick={openModal}>Удалить</button>
                    </div>
                </form>

                <div className={styles.btnSelection}>
                    <select
                        id="viewSelector"
                        className={styles.transaction_type_selector_block}
                        value={selectedView}
                        onChange={handleViewChange}
                    >
                        <option value="debt">Долги</option>
                        <option value="history">История</option>
                    </select>
                </div>
            </div>


            <div className={styles.detailContainer}>

                {selectedView === "debt" ? (
                    <div className={styles.main_debt}>
                        <div className={styles.debtsContainer}>
                            <div className={styles.debts_showcase_names_border}>
                                <h1 className={styles.debt_showcase_names_name}>Долги {amountDebt}</h1>
                                <div className={styles.debts_showcase_names}>
                                    <p className={styles.debt_thename_data} onClick={openModal_date_debt}>Дата и время</p>
                                    <p className={styles.debt_thename_type}>Тип</p>
                                    <p className={styles.debt_thename_amount}>Сумма</p>
                                </div>
                            </div>

                            <div className={styles.debt}>
                                {filteredDebts.map((debt) => (
                                    <div className={styles.debts_border} key={debt.id}>
                                        <div
                                            className={styles.transaction_showcase_apiData}
                                            onClick={() => openModalDebtDelete(debt.id)}
                                        >
                                            <p className={styles.debt_data}>{formatDate(debt.date)}</p>
                                            <p className={styles.debt_method_display}>{debt.method_display}</p>
                                            <p className={styles.debt_amount}>{debt.amount}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                        <div className={styles.btns}>
                            <button type="button" className={styles.button_add_debt} onClick={openModalMethdo_r}>Добавить в долг</button>
                            <button type="button" className={styles.button_refund_debt} onClick={openModalMethdo_d}>Возврат долга</button>
                        </div>
                    </div>
                ) : (
                    <div className={styles.transactionsContainer}>
                        <div className={styles.transaction_showcase_names_border}>
                            <h1 className={styles.transaction_showcase_names_name}>История</h1>
                            <div className={styles.transaction_showcase_names}>
                                <p className={styles.transaction_thename_data} onClick={openModal_date}>Дата и время</p>
                                <p className={styles.transaction_thename_face}>Наименование</p>
                                <p className={styles.transaction_thename_quantity}>Количество</p>
                                <p className={styles.transaction_thename_price}>Цена</p>
                                <p className={styles.transaction_thename_amount}>Сумма</p>
                                <select
                                    className={styles.transaction_type_selector}
                                    onChange={(e) => setTransactionType(e.target.value)}
                                >
                                    <option value="Все">Все</option>
                                    <option value="Продажа">Продажа</option>
                                    <option value="Покупка">Покупка</option>
                                </select>
                            </div>
                        </div>

                        <div className={styles.transactions}>
                            {filteredTransactions.map((transaction) => (
                                <div className={styles.transactions_border} key={transaction.id}>
                                    <div className={styles.transaction_showcase_apiData}>
                                        <p className={styles.transaction_name}>{formatDate(transaction.date)}</p>
                                        <p className={styles.transaction_person_name}>{transaction.product_name}</p>
                                        <p className={styles.transaction_quantity}>{transaction.quantity}</p>
                                        <p className={styles.transaction_price}>{transaction.price}</p>
                                        <p className={styles.transaction_amount}>{transaction.amount}</p>
                                        <p className={styles.transaction_transaction_type_display}>{transaction.transaction_type_display}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>


            {isModalOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Удалении клиента</h1>
                        <p className={styles.model_delete_product_text}>Вы уверены что хотите удалить?</p>
                        <div className={styles.model_delete_product}>
                            <button className={styles.closeModalButton} onClick={closeModal}>
                                Отмена
                            </button>
                            <button type="button" className={styles.submitButton_delete} onClick={deletePerson} >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isModalDateIsOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Фильтование по дате</h1>
                        <form>
                            <div className={styles.dateFilterContainer}>
                                <label className={styles.date_label}>Начальная дата:</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}

                                />
                                <label>Конечная дата:</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>

                            <button type="button" onClick={closeModal_date} className={styles.applyButton}>Применить</button>
                        </form>
                    </div>
                </div>
            )}

            {isModalDateIsOpenDebt && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Фильтование по дате</h1>
                        <form>
                            <div className={styles.dateFilterContainer}>
                                <label className={styles.date_label}>Начальная дата:</label>
                                <input
                                    type="date"
                                    value={startDateDebt}
                                    onChange={(e) => setStartDateDebt(e.target.value)}

                                />
                                <label>Конечная дата:</label>
                                <input
                                    type="date"
                                    value={endDateDebt}
                                    onChange={(e) => setEndDateDebt(e.target.value)}
                                />
                            </div>

                            <button type="button" onClick={closeModal_date_debt} className={styles.applyButton}>Применить</button>
                        </form>
                    </div>
                </div>
            )}

            {isModalDebtMethod_r && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h2 className={styles.title_product_create_form}>Добавление долга</h2>
                        <form onSubmit={handleCreateDebt_r}>

                            <div className={styles.formGroup}>
                                <label htmlFor="amount" className={styles.create_product_label}>Сумма</label>
                                <input
                                    type="number"
                                    id="amount"
                                    value={amount}
                                    onChange={handleAmountChange}
                                    placeholder="Введите Сумму"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="cash" className={styles.create_product_label}>Наличные</label>
                                <input
                                    type="number"
                                    id="cash"
                                    value={cash}
                                    onChange={handleCashChange}
                                    placeholder="Наличные"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="non_cash" className={styles.create_product_label}>Безналичные</label>
                                <input
                                    type="number"
                                    id="non_cash"
                                    value={nonCash}
                                    onChange={handleNonCashChange}
                                    placeholder="Безналичные"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="date" className={styles.create_product_label}>Дата</label>
                                <input
                                    type="date"
                                    id="date"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    placeholder="Дата"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>
                            <button type="submit" className={styles.submitButton_create_debt}>
                                Создать
                            </button>
                        </form>
                        <button className={styles.closeModalButton_debt} onClick={closeModalMethdo_r}>
                            Закрыть
                        </button>
                    </div>
                </div>
            )}

            {isModalDebtMethod_d && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h2 className={styles.title_product_create_form}>Возврат долга</h2>
                        <form onSubmit={handleCreateDebt_d}>

                            <div className={styles.formGroup}>
                                <label htmlFor="amount" className={styles.create_product_label}>Сумма</label>
                                <input
                                    type="number"
                                    id="amount"
                                    value={amount}
                                    onChange={handleAmountChange}
                                    placeholder="Введите Сумму"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="cash" className={styles.create_product_label}>Наличные</label>
                                <input
                                    type="number"
                                    id="cash"
                                    value={cash}
                                    onChange={handleCashChange}
                                    placeholder="Наличные"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="non_cash" className={styles.create_product_label}>Безналичные</label>
                                <input
                                    type="number"
                                    id="non_cash"
                                    value={nonCash}
                                    onChange={handleNonCashChange}
                                    placeholder="Безналичные"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="date" className={styles.create_product_label}>Дата</label>
                                <input
                                    type="date"
                                    id="date"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    placeholder="Дата"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>
                            <button type="submit" className={styles.submitButton_create_debt}>
                                Создать
                            </button>
                        </form>
                        <button className={styles.closeModalButton_debt} onClick={closeModalMethdo_d}>
                            Закрыть
                        </button>
                    </div>
                </div>
            )}

            {isModalDebtDelete && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Подтвердите удаление</h1>
                        <p className={styles.model_delete_product_text}>Вы уверены что хотите удалить?</p>
                        <div className={styles.model_delete_product}>
                            <button className={styles.closeModalButton} onClick={closeModalDebtDelete}>
                                Отмена
                            </button>
                            <button type="button" className={styles.submitButton_delete} onClick={handleDeleteConfirm} >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
