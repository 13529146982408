import { Routes, Route } from 'react-router-dom';
import './App.css';
import { Login } from './pages/Login/Login';
import { Showcase } from './pages/Showcase/Showcase';
import { Main } from './pages/Main/Main';
import { Customer } from './pages/Customer/Customer';
import { ProductDetail } from './pages/ProductDetail/ProductDetail';
import { PersonDetail } from './pages/PersonDetail/PersonDetail';
import { Supplier } from './pages/Supplier/Supplier';
import { Loader } from './components/Loader/Loader';
import { ReceiptDetail } from './pages/ReceiptDetail/ReceiptDetail';
import { DebtDetail } from './pages/DebtDetail/DebtDetail';
import { RecordDetail } from './pages/RecordDetail/RecordDetail';
import { AdminSite } from './pages/AdminSite/AdminSite';
import { SalePage } from './components/SalePage/SalePage';
import { Constant } from './pages/Constant/Constant';
import { ConstantDetail } from './pages/ConstantDetail/ConstantDetail';
import { DailyReport } from './pages/DailyReport/DailyReport';
import { Kassa } from './pages/Kassa/Kassa';
import { DayDetail } from './pages/DayDetail/DayDetail';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FinanceReport } from './pages/Finance/FinanceReport';
import { Coming } from './components/Coming/Coming';
import { Refund } from './components/Refund/Refund';
import { Employee } from './pages/Employee/Employee';
import { MainEmployee } from './pages/MainEmployee/MainEmployee';

function App() {


  return (
    <div>
      <Routes>
        <Route path="/customer" element={<Customer />} />
        <Route path="/" element={<Main />} />
        <Route path="/login" element={<Login />} />
        <Route path="/showcase" element={<Showcase />} />
        <Route path="/supplier" element={<Supplier />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/person/:id" element={<PersonDetail />} />
        <Route path="/loader" element={<Loader />} />
        <Route path="/receipt/:id" element={<ReceiptDetail />} />
        <Route path="/debt/:id" element={<DebtDetail />} />
        <Route path="/record/:id" element={<RecordDetail />} />
        <Route path="/adminsite" element={<AdminSite />} />
        <Route path='/salePage' element={<SalePage />} />
        <Route path='/constant' element={<Constant />} />
        <Route path='/constant/:id' element={<ConstantDetail />} />
        <Route path='/dailyReport' element={<DailyReport />} />
        <Route path='/kassa' element={<Kassa />} />
        <Route path='/days/:id' element={<DayDetail />} />
        <Route path='/financeReport/' element={<FinanceReport />} />
        <Route path='/coming' element={<Coming />} />
        <Route path='/refund' element={<Refund />} />
        <Route path='/employee' element={<Employee />} />
        <Route path='/non_administrator' element={<MainEmployee />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
