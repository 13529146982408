import React, { useState, useEffect } from 'react';
import styles from './DebtDetail.module.css'
import axios from 'axios';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import { toast } from 'react-toastify';
import { Loader } from '../../components/Loader/Loader';

export const DebtDetail = () => {
    const { id } = useParams();
    const [debt, setDebt] = useState([]);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const fetchDebtDetail = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/debts/${id}`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setDebt(response.data);
            setTimeout(() => {
                setIsLoading(false);
            }, 800)
        } catch (error) {
            console.error("Error fetching receipt details:", error);
            toast.error("Ошибка при загрузке информации о долге.");
        }
    };

    const deleteDebt = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_MAIN_URL}/debts/${id}`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            toast.success("Долг успешно удален.");
            navigate('/');
        } catch (error) {
            console.error("Error deleting debt:", error);
            toast.error("Ошибка при удалении долг.");
        }
    };

    useEffect(() => {
        if (!token) {
            toast.error("Пожалуйста, войдите в систему");
            navigate('/login')
            return;
        }
        fetchDebtDetail();
    }, [id, token]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />
            <div className={styles.modalOverlay}>
                <div className={styles.modalContent}>
                    <h1 className={styles.model_delete_product_h1}>Удаление товара</h1>
                    <p className={styles.model_delete_product_text}>Вы уверены что хотите удалить?</p>
                    <div className={styles.model_delete_product}>
                    <div className={styles.container_products}>
                                <p className={styles.container_product}>{debt.method_display}</p>
                                <p className={styles.container_product_person}>{debt.person.type_p_display}:{debt.person.name}</p>
                                <p className={styles.container_product}>{Math.floor(debt.amount)}</p>
                            </div>
                        <div className={styles.btn_container}>
                            <Link to='/' className={styles.closeModalButton}>
                                Отмена
                            </Link>
                            <button
                                type="button"
                                className={styles.submitButton_delete}
                                onClick={deleteDebt}
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};