import React, { useEffect, useState } from 'react';
import styles from './Customer.module.css';
import axios from 'axios';
import Fuse from 'fuse.js';
import { toast } from "react-toastify";
import { Header } from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';

export const Customer = () => {
    const [persons, setPersons] = useState([]);
    const [filteredPersons, setFilteredPersons] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalDebt, setTotalDebt] = useState(0);
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const token = localStorage.getItem("token");
    if (!token) {
        toast.error("Пожалуйста, войдите в систему");
        navigate('/login')
    }

    const fetchPersonList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/persons/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            const filteredPersons = response.data.filter(person => person.type_p === 'c');
            setPersons(filteredPersons);
            setFilteredPersons(filteredPersons);

            setTimeout(() => {
                setIsLoading(false);
            }, 800);

            const totalQty = filteredPersons.length;
            const totalSum = filteredPersons.reduce((sum, person) => {
                const debt = parseFloat(person.debt) || 0;
                return sum + debt;
            }, 0);

            setTotalQuantity(totalQty);
            setTotalDebt(totalSum);
        } catch (error) {
            console.error("Error fetching data:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            }
        }
    };

    const fuse = new Fuse(persons, {
        keys: ['name', 'phone'],
        threshold: 0.4,
    });

    const handleSearchInputChange = (event) => {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);

        const results = searchTerm
            ? fuse.search(searchTerm).map(result => result.item)
            : persons;
        setFilteredPersons(results);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newPerson = await fetchPersonCreate();

        if (newPerson) {
            setPersons((prevPersons) => [...prevPersons, newPerson]);
            setFilteredPersons((prevPersons) => [...prevPersons, newPerson]);

            setName('');
            setNumber('');

            closeModal();
        }
    };

    const fetchPersonCreate = async () => {
        try {
            const personData = {
                type_p: "c",
                name,
                phone: number,
            };

            const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/persons/`, personData, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            const createdPerson = response.data;
            toast.success('Клиент успешно создана!');
            return createdPerson;
        } catch (error) {
            console.error('Ошибка при создании клиента:', error);
            toast.error('Не удалось создать клиенты. Попробуйте еще раз.');
        }
    };

    const handleProductClick = (id) => {
        navigate(`/person/${id}`);
    };

    useEffect(() => {
        fetchPersonList();
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />
            <div className={styles.person_block}>
                <div className={styles.person_container}>
                    <div>
                        <h1 className={styles.person_title}>Клиенты</h1>
                    </div>

                    <div className={styles.persons_showcase_data}>
                        <div className={styles.person_report_border}>
                            <div className={styles.person_report_block}>
                                <div className={styles.person_report}>
                                    <p className={styles.total_data}>Общее количество: {totalQuantity}</p>
                                    <p className={styles.total_data}>Общее количество долгов: {totalDebt.toFixed(2)}</p>
                                    <button className={styles.btn_person_create} onClick={openModal}>Создать</button>
                                </div>
                                <div className={styles.person_search}>
                                    <input
                                        type="text"
                                        placeholder="Поиск"
                                        className={styles.search_input}
                                        value={searchTerm}
                                        onChange={handleSearchInputChange}
                                    />
                                    <button className={styles.person_search_btn}>Поиск</button>
                                </div>
                            </div>
                        </div>

                        <div className={styles.persons_showcase_names_block}>
                            <div className={styles.persons_showcase_names}>
                                <p className={styles.person_thename}>№</p>
                                <p className={styles.person_thename}>Клиент</p>
                                <p className={styles.person_thename}>Долг</p>
                            </div>
                        </div>

                        <div className={styles.person_list}>
                            {filteredPersons
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((person, index) => (
                                    <div className={styles.person_list_container} key={person.id}>
                                        <div
                                            className={styles.persons_showcase_apiData}
                                            onClick={() => handleProductClick(person.id)}
                                        >
                                            <p className={styles.persons_number}>{index + 1}</p>
                                            <p className={styles.persons_name}>{person.name}</p>
                                            <p className={styles.persons_debt}>{person.debt}</p>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h2 className={styles.title_person_create_form}>Создание клиента</h2>
                        <form onSubmit={handleFormSubmit}>
                            <div className={styles.formGroup}>
                                <label htmlFor="personName" className={styles.create_person_label}>Имя</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Введите имя"
                                    className={styles.create_person_input}
                                    required
                                />
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="phone" className={styles.create_person_label}>Номер телефона</label>
                                <input
                                    type="text"
                                    id="number"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                    placeholder="Введите номер телефона"
                                    className={styles.create_person_input}
                                />
                            </div>
                            <button type="submit" className={styles.submitButton}>
                                Создать
                            </button>
                        </form>
                        <button className={styles.closeModalButton} onClick={closeModal}>
                            Закрыть
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
