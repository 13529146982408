import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Header } from '../../components/Header/Header';
import styles from './ReceiptDetail.module.css';
import { toast } from 'react-toastify';
import { Loader } from '../../components/Loader/Loader';

export const ReceiptDetail = () => {
    const { id } = useParams();
    const [receipt, setReceipt] = useState({ transactions: [] });
    const token = localStorage.getItem('token');
    const navigate = useNavigate(); 
    const [isLoading, setIsLoading] = useState(true);

    const fetchReceiptDetail = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/receipts/${id}`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setReceipt(response.data);
            setTimeout(() => {
                setIsLoading(false);
            }, 800)
        } catch (error) {
            console.error("Error fetching receipt details:", error);
            toast.error("Ошибка при загрузке информации о чеке.");
        }
    };

    const deleteReceipt = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_MAIN_URL}/receipts/${id}`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            toast.success("Чек успешно удален.");
            navigate('/');
        } catch (error) {
            console.error("Error deleting receipt:", error);
            toast.error("Ошибка при удалении чека.");
        }
    };

    useEffect(() => {
        if (!token) {
            toast.error("Пожалуйста, войдите в систему");
            navigate('/login')
            return;
        }
        fetchReceiptDetail();
    }, [id, token]);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />
            <div className={styles.modalOverlay}>
                <div className={styles.modalContent}>
                    <h1 className={styles.model_delete_product_h1}>Удаление товара</h1>
                    <p className={styles.model_delete_product_text}>Вы уверены что хотите удалить?</p>
                    <div className={styles.model_delete_product}>
                        <div className={styles.receipt_container}>
                            {receipt.transactions.map((transaction, index) => (
                                <div className={styles.container_products} key={transaction.id}>
                                    <p className={styles.container_product}>{index + 1}..{transaction.product_name}</p>
                                    <p className={styles.container_product}>
                                        {transaction.quantity} x {Math.floor(transaction.price)}
                                    </p>
                                    <p className={styles.container_product}>{Math.floor(transaction.amount)}</p>
                                </div>
                            ))}
                        </div>
                        <div className={styles.btn_container}>
                        <Link to='/' className={styles.closeModalButton}>
                            Отмена
                        </Link>
                        <button
                            type="button"
                            className={styles.submitButton_delete}
                            onClick={deleteReceipt}
                        >
                            Удалить
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
