import React, { useState, useEffect } from 'react';
import { Header } from '../../components/Header/Header'
import styles from './MainEmployee.module.css'
import moment from 'moment';
import { toast } from "react-toastify";
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';
import { SalePage } from '../../components/SalePage/SalePage';
import { Refund } from '../../components/Refund/Refund';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


export const MainEmployee = () => {

    const today = moment().format('YYYY-MM-DD');
    const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
    const token = localStorage.getItem("token");
    const [date, setDate] = useState(today);
    const [method, setMethod] = useState('');
    const [name, setName] = useState('');
    const [amount, setAmount] = useState('');
    const [constant, setConstant] = useState('');
    const [constantData, setConstantData] = useState([]);
    const [saletransactions, setSaleTransactions] = useState([])
    const [records, setRecords] = useState([]);
    const [refund, setRefund] = useState([])
    const [debtlist, setDebtlist] = useState([])
    const [purchasetransactions, setPurchaseTransactions] = useState([])
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(tomorrow);
    const [isModalDateIsOpen, setModalDateIsOpen] = useState(false);
    const [isModalOpenOther, setModalOpenOther] = useState(false);
    const [isModalOpenSale, setIsModalOpenSale] = useState(false);
    const [isModalOpenComming, setIsModalOpenComming] = useState(false);
    const [isModalOpenRefund, setIsModalOpenRefund] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isModalRecordCreate, setModalRecordCreate] = useState(false);
    const [showPrices, setShowPrices] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('продажа');

    const [data, setData] = useState([])
    const navigate = useNavigate()


    const fetchTransactionListSale = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/transactions/?transaction_type=sale&date__range=${startDate},${endDate}&ordering=-date`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setSaleTransactions(response.data);
            setTimeout(() => {
                setIsLoading(false);
            }, 800)
        } catch (error) {
            console.error("Ошибка при загрузке транзакций:", error);
            toast.error("Ошибка при загрузке транзакций.");
        }
    };

    const fetchTransactionListPurchase = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/transactions/?transaction_type=purchase&date__range=${startDate},${endDate}&ordering=-date`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setPurchaseTransactions(response.data);
            setTimeout(() => {
                setIsLoading(false);
            }, 800)
        } catch (error) {
            console.error("Ошибка при загрузке транзакций:", error);
            toast.error("Ошибка при загрузке транзакций.");
        }
    };

    const fetchRecordList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/records/?date__range=${startDate},${endDate}&ordering=-date`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setRecords(response.data);
            setTimeout(() => {
                setIsLoading(false);
            }, 800)
        } catch (error) {
            console.error("Ошибка при загрузке транзакций:", error);
            toast.error("Ошибка при загрузке транзакций.");
        }
    };

    const fetchRecordCreate = async (recordData) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/records/`, recordData, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            const createdRecord = response.data;
            toast.success('Запись успешно создана!');
            setTimeout(() => {
                window.location.reload();
            }, 800)
            return createdRecord;
        } catch (error) {
            console.error('Ошибка при создании записи:', error);
            toast.error('Не удалось создать запись. Попробуйте еще раз.');
        }
    };

    const fetchConstantList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/constants/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            setConstantData(response.data);
            setTimeout(() => {
                setIsLoading(false);
            }, 800);

        } catch (error) {
            console.error("Error fetching data:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            }
        }
    };

    const fetchRefundList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/transactions/?transaction_type=refund&date__range=${startDate},${endDate}&ordering=-date`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setRefund(response.data);
            setTimeout(() => {
                setIsLoading(false);
            }, 800)
        } catch (error) {
            console.error("Ошибка при загрузке транзакций:", error);
            toast.error("Ошибка при загрузке транзакций.");
        }
    };

    const fetchDebtList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/debts/?date__range=${startDate},${endDate}&ordering=-date`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setDebtlist(response.data);
            setTimeout(() => {
                setIsLoading(false);
            }, 800)
        } catch (error) {
            console.error("Ошибка при загрузке транзакций:", error);
            toast.error("Ошибка при загрузке транзакций.");
        }
    };

    const fetchDailyList = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_MAIN_URL}/days/?ordering=-date`,
                {
                    headers: {
                        Authorization: `token ${token}`,
                    },
                }
            );

            const fetchedData = response.data;
            const to_day = moment().format('YYYY-MM-DD');

            const filteredData = fetchedData.filter((transaction) =>
                moment(transaction.date).format('YYYY-MM-DD') === to_day
            );

            setData(filteredData.length > 0 ? filteredData[0] : null);
            setTimeout(() => {
                setIsLoading(false);
            }, 800);

        } catch (error) {
            console.error("Error fetching product details:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            } else {
                toast.error("Ошибка при загрузке данных.");
            }
        }
    };

    const handleDateChange = (event) => {
        setDate(event.target.value);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const recordData = {
            method,
            name,
            amount,
            cash: amount,
            date: moment().format('YYYY-MM-DD HH:mm'),
            constant,
        };

        const newRecord = await fetchRecordCreate(recordData);

        if (newRecord) {
            setMethod('');
            setName('');
            setAmount('');
            setConstant('');

            closeModalRecordCreate();
        }
    };

    const confirmUpdateDailyReport = async (id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/days/${id}/calc/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            const response_finance = await axios.get(`${process.env.REACT_APP_MAIN_URL}/days/${id}/culc_additional/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            console.log('Response data:', response.data);
            toast.success('Данные успешно обновлены!');
            window.location.reload();

            fetchDailyList();
        } catch (error) {
            console.error('Ошибка при обновлении данных:', error);
            toast.error('Не удалось обновить данные. Попробуйте еще раз.');
        }
    };

    const openModal_date = () => setModalDateIsOpen(true);
    const closeModal_date = () => {
        setModalDateIsOpen(false);
        fetchTransactionListSale();
        fetchTransactionListPurchase();
        fetchRecordList();
        fetchRefundList();
        fetchDebtList();
    };

    const openModelOther = () => setModalOpenOther(true)
    const closeModelOther = () => setModalOpenOther(false)

    const openModalRecordCreate = () => {
        setModalRecordCreate(true);
        closeModelOther();
    }
    const closeModalRecordCreate = () => setModalRecordCreate(false)

    const filteredTransactionsSales = saletransactions.filter((transaction) => {
        const transactionDate = moment(transaction.date);
        const isAfterStartDate = startDate ? transactionDate.isSameOrAfter(moment(startDate), 'day') : true;
        const isBeforeEndDate = endDate ? transactionDate.isSameOrBefore(moment(endDate), 'day') : true;
        return isAfterStartDate && isBeforeEndDate;
    });

    const filteredPurchaseTransactions = purchasetransactions.filter((transaction) => {
        const transactionDate = moment(transaction.date);
        const isAfterStartDate = startDate ? transactionDate.isSameOrAfter(moment(startDate), 'day') : true;
        const isBeforeEndDate = endDate ? transactionDate.isSameOrBefore(moment(endDate), 'day') : true;
        return isAfterStartDate && isBeforeEndDate;
    });

    const formattedRecords = records.filter((transaction) => {
        const transactionDate = moment(transaction.date);
        const isAfterStartDate = startDate ? transactionDate.isSameOrAfter(moment(startDate), 'day') : true;
        const isBeforeEndDate = endDate ? transactionDate.isSameOrBefore(moment(endDate), 'day') : true;
        return isAfterStartDate && isBeforeEndDate;
    });

    const formattedRefund = refund.filter((transaction) => {
        const transactionDate = moment(transaction.date);
        const isAfterStartDate = startDate ? transactionDate.isSameOrAfter(moment(startDate), 'day') : true;
        const isBeforeEndDate = endDate ? transactionDate.isSameOrBefore(moment(endDate), 'day') : true;
        return isAfterStartDate && isBeforeEndDate;
    });

    const formattedDebt = debtlist.filter((transaction) => {
        const transactionDate = moment(transaction.date);
        const isAfterStartDate = startDate ? transactionDate.isSameOrAfter(moment(startDate), 'day') : true;
        const isBeforeEndDate = endDate ? transactionDate.isSameOrBefore(moment(endDate), 'day') : true;
        return isAfterStartDate && isBeforeEndDate;
    });

    const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
    const weekdays = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];

    const dateMoment = moment(date);
    const month = months[dateMoment.month()];
    const weekday = weekdays[dateMoment.day()];

    const openModal = () => {
        setIsModalOpenSale(true);
    };

    const exit = () => {
        setIsModalOpenSale(false);
    };


    const openModalRefund = () => {
        setIsModalOpenRefund(true)
    }

    const exitModalRefund = () => {
        setIsModalOpenRefund(false)
    }


    const formatDate = (dateStr) => {
        return moment(dateStr).format('DD.MM.YYYY');
    };

    const handleReceiptClick = (id) => {
        navigate(`/receipt/${id}`);
    };

    const handleDebtClick = (id) => {
        navigate(`/debt/${id}`);
    };

    const handleRecordClick = (id) => {
        navigate(`/record/${id}`);
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const toggleShowPrices = () => {
        setShowPrices(!showPrices);
    };

    useEffect(() => {
        if (!token) {
            toast.error("Пожалуйста, войдите в систему");
            navigate('/login')
            return;
        }
        fetchTransactionListSale();
        fetchTransactionListPurchase();
        fetchRecordList();
        fetchRefundList();
        fetchDebtList();
        fetchConstantList();
        fetchDailyList();
    }, [token]);


    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />
            <div className={styles.salePage}>
                <SalePage today={startDate} />
            </div>
            <div className={styles.date_border}>
                <h1 className={styles.h1_date_border}>
                    {`${month}, ${weekday}`}
                </h1>
                <div className={styles.cont_border}>
                    <input
                        type="date"
                        value={date}
                        className={styles.date_border_date}
                        onChange={handleDateChange}
                    />
                    <select
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        className={styles.category_select}
                    >
                        <option value="продажа">Продажа</option>
                        <option value="прочее">Прочее</option>
                    </select>
                </div>

            </div>

            <div className={styles.container}>
                <div className={styles.btn_cont_border}>
                    <Link to={'/salePage'}>
                        <button className={styles.work_btn} >
                            Продажа
                        </button>
                    </Link>
                </div>
                <div className={styles.btn_cont_border}>
                    <button className={styles.work_btn} onClick={openModelOther}>Прочее</button>
                </div>
            </div>


            <div>
                {selectedCategory === 'продажа' && (
                    <div>
                        <div id="продажа" className={styles.block_result}>
                            {filteredTransactionsSales.map((transaction) => (
                                <div
                                    className={styles.container_products}
                                    key={transaction.id}
                                    onClick={() => handleReceiptClick(transaction.receipt.id)}
                                >
                                    <p className={styles.container_product}>{formatDate(transaction.date)}</p>
                                    <p className={styles.container_product}>{transaction.product_name}</p>
                                    <p className={styles.container_product}>{transaction.quantity}x{Math.floor(transaction.price)}</p>
                                    <p className={styles.container_product}>{Math.floor(transaction.amount)}</p>
                                </div>
                            ))}
                        </div>
                        <div className={styles.container_result_sale}>
                            <div className={styles.container_result_total_amount}>
                                <p className={styles.showPrices}>  Общая Сумма: <span className={styles.showprice}> {showPrices
                                    ? filteredTransactionsSales.reduce((total, transaction) => total + parseFloat(transaction.amount || 0), 0)
                                    : <a> ******* </a>}</span>
                                </p>
                                <button className={styles.toggle_prices_btn} onClick={toggleShowPrices}>
                                    {showPrices ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>
                            <button type="submit" className={styles.btn_open_date} onClick={openModal_date}>
                                Дата
                            </button>
                        </div>
                    </div>
                )}

                {selectedCategory === 'прочее' && (
                    <div>

                        <div id="прочее" className={styles.block_result}>
                            {formattedRecords.map((transaction) => (
                                <div
                                    className={styles.container_products}
                                    key={transaction.id}
                                    onClick={() => handleRecordClick(transaction.id)}
                                >
                                    <p className={styles.container_product}>{formatDate(transaction.date)}</p>
                                    <p className={styles.container_product}>{transaction.name}</p>
                                    <p className={styles.container_product}>{transaction.method === 'd' ? '+' : '-'} {transaction.amount}</p>
                                </div>
                            ))}

                            {formattedRefund.map((transaction) => (
                                <div
                                    className={styles.container_products}
                                    key={transaction.id}
                                    onClick={() => handleReceiptClick(transaction.receipt.id)}
                                >
                                    <p className={styles.container_product}>{formatDate(transaction.date)}</p>
                                    <p className={styles.container_product}>Возврат</p>
                                    <p className={styles.container_product}>{transaction.product_name}</p>
                                    <p className={styles.container_product}>{transaction.quantity}x{Math.floor(transaction.price)}</p>
                                    <p className={styles.container_product}>{Math.floor(transaction.amount)}</p>
                                </div>
                            ))}

                            {formattedDebt.map((transaction) => (
                                <div
                                    className={styles.container_products}
                                    key={transaction.id}
                                    onClick={() => handleDebtClick(transaction.id)}
                                >
                                    <p className={styles.container_product}>{formatDate(transaction.date)}</p>
                                    <p className={`${styles.container_product} ${styles.transaction_method}`}>{transaction.method_display}</p>
                                    <p className={`${styles.container_product} ${styles.person_name}`}>
                                        {transaction.person.type_p_display}:{transaction.person.name}
                                    </p>
                                    <p className={styles.container_product}>{Math.floor(transaction.amount)}</p>
                                </div>
                            ))}

                        </div>
                        <div className={styles.container_result_sale}>
                            <div className=""></div>
                            <button type="submit" className={styles.btn_open_date} onClick={openModal_date}>Дата</button>
                        </div>
                    </div>
                )}
            </div>


            <div className={styles.border_container}>
                <div className={styles.main_date_border}>
                    <div className={styles.basic_container_names}>
                        <div className={styles.names}>
                            <p className={`${styles.fields_names} ${styles.fields_names_data}`} onClick={openModal_date}>Дата</p>
                            <p className={styles.fields_names}>Доход</p>
                            <p className={styles.fields_names}>Расход</p>
                            <p className={styles.fields_names}>Итого</p>
                            <p className={styles.fields_names}>Наличные</p>
                            <p className={styles.fields_names}>Безналичные</p>
                            <p className={styles.fields_names}>#</p>
                            <p className={styles.fields_names}>Количество товаров</p>
                            <p className={styles.fields_names}>#</p>
                        </div>
                    </div>

                    <div className={styles.fields_api}>
                        <div className={styles.api_border}>
                            <div className={styles.api}>
                                <p className={styles.fields_names}>{data.date}</p>
                                <p className={styles.fields_names}>{data.income}</p>
                                <p className={styles.fields_names}>{data.expenses}</p>
                                <p className={styles.fields_names}>{data.total_amount}</p>
                                <p className={styles.fields_names}>{data.cash}</p>
                                <p className={styles.fields_names}>{data.non_cash}</p>
                                <p className={`${styles.fields_names} ${styles.buttons}`}>Детально</p>
                                <p className={styles.fields_names}>{data.total_quantity}</p>
                                <p className={`${styles.fields_names} ${styles.buttons}`} onClick={() => confirmUpdateDailyReport(data.id)}>Рассчитать</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isModalDateIsOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Фильтрация по дате</h1>
                        <form>
                            <div className={styles.dateFilterContainer}>
                                <label className={styles.date_label}>Начальная дата:</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <label>Конечная дата:</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>

                            <button type="button" onClick={closeModal_date} className={styles.applyButton}>Применить</button>
                        </form>
                    </div>
                </div>
            )}

            {isModalOpenOther && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Прочее</h1>
                        <div className={styles.other_container}>
                            <button className={styles.work_btn} onClick={openModalRecordCreate}>Доход и Расход</button>
                            <button className={styles.work_btn} onClick={openModalRefund}>Возврат</button>
                        </div>
                        <button type="button" onClick={closeModelOther} className={`${styles.applyButton} ${styles.close_button}`}>Закрыть</button>
                    </div>
                </div>
            )}

            {isModalRecordCreate && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Доход и расход</h1>
                        <form onSubmit={handleFormSubmit}>
                            <div className={styles.formGroup}>
                                <select
                                    id="method"
                                    className={styles.formSelect}
                                    value={method}
                                    onChange={(e) => setMethod(e.target.value)}
                                    required
                                >
                                    <option value="">Выберите метод</option>
                                    <option value="r">Расход</option>
                                    <option value="d">Доход</option>
                                </select>
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="constant">Шаблон:</label>
                                <select
                                    value={constant}
                                    onChange={(e) => setConstant(e.target.value)}
                                >
                                    <option value="">Выберите шаблон</option>
                                    {constantData.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="name">Наименование:</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="amount">Сумма:</label>
                                <input
                                    type="number"
                                    id="amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    required
                                />
                            </div>
                            <div className={styles.buttonGroup}>
                                <button type="button" onClick={closeModalRecordCreate} className={styles.cancelButton}>Отмена</button>
                                <button type="submit" className={styles.applyButton}>Создать запись</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {isModalOpenSale && (
                <SalePage date={date} onClick={exit} />
            )}

            {isModalOpenRefund && (
                <Refund date={date} onClick={exitModalRefund} />
            )}

        </div>
    )
}
