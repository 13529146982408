import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import styles from './Constant.module.css';
import axios from 'axios';
import { toast } from "react-toastify";
import { Header } from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';

export const Constant = () => {
    const [constant, setConstant] = useState([]);
    const [filteredConstants, setFilteredConstants] = useState([]);
    const [name, setName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const token = localStorage.getItem("token");
    const is_admin = localStorage.getItem("is_admin");
    if (!token) {
        toast.error("Пожалуйста, войдите в систему");
        navigate('/login')
    }

    if (is_admin === 'false') {
        navigate('/non_administrator');
    }

    const fuse = new Fuse(constant, {
        keys: ['name'],
        threshold: 0.4,
    });

    const fetchConstantList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/constants/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            setConstant(response.data);
            setFilteredConstants(response.data);
            setTimeout(() => {
                setIsLoading(false);
            }, 800);

        } catch (error) {
            console.error("Error fetching data:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            }
        }
    };

    const fetchConstantCreate = async () => {
        try {
            const personData = { name };
            const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/constants/`, personData, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            const createdPerson = response.data;
            toast.success('Персона успешно создана!');
            setConstant((prev) => [...prev, createdPerson]);
            setFilteredConstants((prev) => [...prev, createdPerson]);
            setName('');
            closeModal();
        } catch (error) {
            console.error('Ошибка при создании персоны:', error);
            toast.error('Не удалось создать персону. Попробуйте еще раз.');
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        await fetchConstantCreate();
    };
    
    const handleSearchInputChange = (event) => {
        const searchValue = event.target.value;
        setSearchTerm(searchValue);
        if (searchValue) {
            const results = fuse.search(searchValue).map(result => result.item);
            setFilteredConstants(results);
        } else {
            setFilteredConstants(constant);
        }
    };

    const handleConstantClick = (id) => {
        navigate(`/constant/${id}`);
    };

    useEffect(() => {
        fetchConstantList();
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />
            <div className={styles.person_block}>
                <div className="">
                    <div>
                        <h1 className={styles.person_title}>Шаблон</h1>
                    </div>

                    <div className={styles.persons_showcase_data}>
                        <div className={styles.person_report_border}>
                            <div className={styles.person_report_block}>
                                <div className={styles.person_search}>
                                    <input
                                        type="text"
                                        placeholder="Поиск"
                                        className={styles.search_input}
                                        value={searchTerm}
                                        onChange={handleSearchInputChange}
                                    />
                                    <button className={styles.person_search_btn}>Поиск</button>
                                    <button className={styles.btn_person_create} onClick={openModal}>Создать</button>
                                </div>
                            </div>
                        </div>

                        <div className={styles.persons_showcase_names}>
                            <p className={styles.person_thename_N}>№</p>
                            <p className={styles.person_thename}>Название</p>
                        </div>

                        <div className={styles.person_list}>
                            {filteredConstants.map((constant, index) => (
                                <div key={constant.id} className={styles.persons_showcase_apiData} onClick={() => handleConstantClick(constant.id)}>
                                    <p className={styles.persons_number}>{index + 1}</p>
                                    <p className={styles.persons_name}>{constant.name}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            
            {isModalOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h2 className={styles.title_person_create_form}>Создать шаблон</h2>
                        <form onSubmit={handleFormSubmit}>
                            <div className={styles.formGroup}>
                                <label htmlFor="name" className={styles.create_person_label}>Имя</label>
                                <input
                                    type="text"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Введите имя"
                                    className={styles.create_person_input}
                                    required
                                />
                            </div>
                            <button type="submit" className={styles.submitButton}>
                                Создать
                            </button>
                        </form>
                        <button className={styles.closeModalButton} onClick={closeModal}>
                            Закрыть
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
