import React, { useEffect, useState } from 'react'
import styles from './FinanceReport.module.css'
import axios from 'axios'
import { toast } from 'react-toastify'
import moment from 'moment'
import { Header } from '../../components/Header/Header'
import { Loader } from '../../components/Loader/Loader'
import { useNavigate } from 'react-router-dom'

export const FinanceReport = () => {
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem("token");
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [data, setData] = useState([]);
    const [isModalDateIsOpen, setModalDateIsOpen] = useState(false);
    const navigate = useNavigate()

    const is_admin = localStorage.getItem("is_admin");

    if (is_admin === 'false') {
        navigate('/non_administrator');
    }

    const fetchDailyList = async () => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/days/?date__range=${startDate},${endDate}&ordering=-date`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            const fetchedData = response.data;
            const filteredData = fetchedData.filter((transaction) => {
                const transactionDate = moment(transaction.date);
                const isAfterStartDate = startDate ? transactionDate.isSameOrAfter(moment(startDate), 'day') : true;
                const isBeforeEndDate = endDate ? transactionDate.isSameOrBefore(moment(endDate), 'day') : true;
                return isAfterStartDate && isBeforeEndDate;
            });

            setData(filteredData);
            setTimeout(() => {
                setIsLoading(false);
            }, 800);

        } catch (error) {
            console.error("Error fetching product details:", error);

            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            } else {
                toast.error("Ошибка при загрузке данных.");
            }
        }
    };

    useEffect(() => {
        if (!token) {
            toast.error("Пожалуйста, войдите в систему");
            navigate('/login')
            return;
        }

        fetchDailyList();
    }, [startDate, endDate])

    const openModal_date = () => setModalDateIsOpen(true);
    const closeModal_date = () => setModalDateIsOpen(false);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />
            <div>
                <h1 className={styles.dailyReport_h1}>Финансовый отчет</h1>
                <div className={styles.border_container}>
                    <div className={styles.container}>
                        <div className={styles.basic_container_names}>
                            <div className={styles.names}>
                                <p className={`${styles.fields_names} ${styles.fields_names_data}`} onClick={openModal_date}>Дата</p>
                                <p className={styles.fields_names}>Наличные</p>
                                <p className={styles.fields_names}>Безналичные</p>
                                <p className={styles.fields_names}>Касса</p>
                                <p className={styles.fields_names}>Товаров на сумму</p>
                                <p className={styles.fields_names}>Долги мне</p>
                                <p className={styles.fields_names}>Мои долги</p>
                                <p className={styles.fields_names}>Оборот</p>
                            </div>
                        </div>

                        <div className={styles.fields_api}>
                            {data.map((data) => (
                                <div className={styles.api_border}>
                                    <div className={styles.api} key={data.id}>
                                        <p className={styles.fields_names}>{data.date}</p>
                                        <p className={styles.fields_names}>
                                            {data.additional ? data.additional.cash : "N/A"}</p>
                                        <p className={styles.fields_names}>
                                            {data.additional ? data.additional.non_cash : 'N/A'}</p>
                                        <p className={styles.fields_names}>
                                            {data.additional ? data.additional.total_cash : 'N/A'}</p>
                                        <p className={styles.fields_names}>
                                            {data.additional ? data.additional.products_amount : 'N/A'}</p>
                                        <p className={styles.fields_names}>
                                            {data.additional ? data.additional.total_debt_buyer : 'N/A' }</p>
                                        <p className={styles.fields_names}>
                                            {data.additional ? data.additional.total_debt_supplier : 'N/A'}</p>
                                        <p className={styles.fields_names}>
                                            {data.additional ? data.additional.total_turnover : 'N/A'}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {isModalDateIsOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Фильтование по дате</h1>
                        <form>
                            <div className={styles.dateFilterContainer}>
                                <label className={styles.date_label}>Начальная дата:</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <label>Конечная дата:</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                            <button type="button" onClick={closeModal_date} className={styles.applyButton}>Применить</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}
