import React, { useState, useEffect } from 'react';
import styles from './Employee.module.css'
import Fuse from 'fuse.js';
import axios from 'axios';
import { toast } from "react-toastify";
import { Header } from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';
import { FaEye, FaEyeSlash, FaTrash } from 'react-icons/fa';


export const Employee = () => {
    const [shopMemberShip, setShopMemberShip] = useState([]);
    const [filteredShopMemberShips, setFilteredShopMemberShips] = useState([]);
    const [user, setUser] = useState('');
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const navigate = useNavigate();

    const is_admin = localStorage.getItem("is_admin");
    const token = localStorage.getItem("token");
    if (!token) {
        toast.error("Пожалуйста, войдите в систему");
        navigate('/login')
    }

    if (is_admin === 'false') {
        navigate('/non_administrator');
    }


    const fetchShopMemberShipList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/shopmembership/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            setShopMemberShip(response.data);
            setFilteredShopMemberShips(response.data);
            setTimeout(() => {
                setIsLoading(false);
            }, 800);

        } catch (error) {
            console.error("Error fetching data:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            }
        }
    };


    const fetchEmployeeRegister = async () => {
        try {
            if (password.length < 8) {
                toast.error('Пароль должен содержать минимум 8 символов.');
                return;
            }

            if (password !== confirmPassword) {
                toast.error('Пароли не совпадают.');
                return;
            }

            const employeeData = {
                username,
                password,
            };

            const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/register/`, employeeData, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            console.log('Response data:', response.data);
            const createdUserId = response.data?.user_id;

            if (!createdUserId) {
                toast.error('ID пользователя отсутствует в ответе сервера.');
                return;
            }

            await fetchCreateShopMembership(createdUserId);

            toast.success('Пользователь успешно создан!');
            closeModal();
            return createdUserId;
        } catch (error) {
            console.error('Ошибка при создании пользователя:', error);
            toast.error('Не удалось создать пользователя. Попробуйте еще раз.');
        }
    };


    const fetchCreateShopMembership = async (userId) => {

        const shopId = localStorage.getItem('shop_id');

        const employeeData = {
            user: userId,
            shop: shopId,
            is_admin: false,
        };

        const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/shopmembership/`, employeeData, {
            headers: {
                Authorization: `token ${token}`,
            },
        });

        window.location.reload();
        return response.data;
    };

    const deleteUser = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_MAIN_URL}/users/${id}/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            toast.success("Пользователь успешно удалён!");
            navigate('/employee');
        } catch (error) {
            console.error("Ошибка при удалении пользователь:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            } else if (error.response && error.response.status === 404) {
                toast.error("Продукт не найден.");
            } else {
                toast.error("Ошибка при удалении пользователь.");
            }
        }
    };

    const updateAdminStatus = async (userId, isAdmin) => {
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_MAIN_URL}/shopmembership/${userId}/`,
                { is_admin: isAdmin },
                {
                    headers: {
                        Authorization: `token ${token}`,
                    },
                }
            );
            window.location.reload()
            toast.success('Статус администратора успешно обновлён!');
        } catch (error) {
            console.error('Ошибка при обновлении статуса администратора:', error);
            toast.error('Не удалось обновить статус администратора.');
        }
    };



    const handleFormSubmit = async (e) => {
        e.preventDefault();

        await fetchEmployeeRegister();

        setUsername('');
        setPassword('');

        closeModal();
    };


    const toggleShowPassword = () => {
        setShowPassword((prev) => !prev);
    };


    const fuse = new Fuse(shopMemberShip, {
        keys: ['user_username'],
        threshold: 0.4,
    });


    const handleSearch = (event) => {
        const term = event.target.value.trim();
        setSearchTerm(term);
        const results = term ? fuse.search(term).map(({ item }) => item) : shopMemberShip;
        setFilteredShopMemberShips(results);
    };


    useEffect(() => {
        fetchShopMemberShipList();
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);


    const openDeleteModal = (id) => {
        setSelectedUserId(id);
        setIsModalOpenDelete(true);
    };

    const closeDeleteModal = () => {
        setSelectedUserId(null);
        setIsModalOpenDelete(false);
    };



    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />
            <div className={styles.person_block}>
                <div className="">
                    <div>
                        <h1 className={styles.person_title}>Сотрудники</h1>
                    </div>

                    <div className={styles.persons_showcase_data}>
                        <div className={styles.person_report_border}>
                            <div className={styles.person_report_block}>
                                <div className={styles.person_search}>
                                    <input
                                        type="text"
                                        placeholder="Поиск"
                                        className={styles.search_input}
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                    <button className={styles.person_search_btn}>Поиск</button>
                                    <button className={styles.btn_person_create} onClick={openModal}>Создать</button>
                                </div>
                            </div>
                        </div>

                        <div className={styles.persons_showcase_names}>
                            <p className={styles.person_thename_N}>№</p>
                            <p className={styles.person_thename}>Название</p>
                            <p className={styles.person_thename}>Cтатус</p>
                        </div>

                        <div className={styles.person_list}>
                            {filteredShopMemberShips.map((user, index) => (
                                <div key={user.id} className={styles.persons_showcase_apiData}>
                                    <p className={styles.persons_number}>{index + 1}</p>
                                    <p className={styles.persons_name}>{user.user_username}</p>
                                    <p className={styles.persons_name}>{user.is_admin ? 'Администратор' : 'Сотрудник'}</p>

                                    <div className={styles.update_div}>
                                        <label className={styles.toggle_switch}>
                                            <input
                                                type="checkbox"
                                                checked={user.is_admin}
                                                onChange={() => updateAdminStatus(user.id, !user.is_admin)}
                                            />
                                            <span className={styles.slider}></span>
                                        </label>

                                        <button
                                            type="button"
                                            className={styles.delete_button}
                                            onClick={() => openDeleteModal(user.user)}
                                        >
                                            <FaTrash className={styles.delete_icon} />
                                        </button>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h2 className={styles.title_person_create_form}>Регистрация</h2>
                        <form
                            onSubmit={handleFormSubmit}>
                            <div className={styles.formGroup}>
                                <label htmlFor="username" className={styles.create_person_label}>Имя пользователя</label>
                                <input
                                    type="text"
                                    id="username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Введите имя пользователя"
                                    className={styles.create_person_input}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="password" className={styles.create_person_label}>Пароль</label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Введите пароль"
                                    className={styles.create_password_input}
                                    required
                                />
                                <button
                                    type="button"
                                    className={styles.toggle_prices_btn}
                                    onClick={toggleShowPassword}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="confirmPassword" className={styles.create_person_label}>Повторите пароль</label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    placeholder="Повторите пароль"
                                    className={styles.create_person_input}
                                    required
                                />
                            </div>

                            <button type="submit" className={styles.submitButton}>
                                Создать
                            </button>
                        </form>
                        <button className={styles.closeModalButton} onClick={closeModal}>
                            Закрыть
                        </button>
                    </div>
                </div>
            )}

            {isModalOpenDelete && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Удалении пользователя</h1>
                        <p className={styles.model_delete_product_text}>Вы уверены что хотите удалить?</p>
                        <div className={styles.model_delete_product}>
                        <button
                                className={styles.closeModalButton_delete}
                                onClick={closeDeleteModal}
                            >
                                Отмена
                            </button>
                            <button
                                className={styles.submitButton_delete}
                                onClick={() => deleteUser(selectedUserId)}
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};
