import React, { useState, useEffect } from 'react'
import styles from './ConstantDetail.module.css'
import axios from 'axios';
import { Header } from '../../components/Header/Header';
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';

export const ConstantDetail = () => {
    const { id } = useParams();
    const [constant, setConstant] = useState([]);
    const token = localStorage.getItem("token");
    const [name, setName] = useState('');
    const [constantType, setConstantType] = useState("Все");
    const [data, setData] = useState([]);
    const [income, setIncome] = useState(0);
    const [expense, setExpense] = useState(0);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDateIsOpen, setModalDateIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const is_admin = localStorage.getItem("is_admin");

    if (is_admin === 'false') {
        navigate('/non_administrator');
    }

    const fetchConstantDetail = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/constants/${id}/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setConstant(response.data);
            setName(response.data.name);
            setTimeout(() => {
                setIsLoading(false);
            }, 800)
        } catch (error) {
            console.error("Error fetching product details:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            } else {
                toast.error("Ошибка при загрузке деталей продукта.");
            }
        }
    };

    const fetchConstantRecord = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/records/?constant=${id.toString()}`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            const fetchedData = response.data;
            const filteredData = fetchedData.filter((item) => {
                const constantDate = moment(item.date);
                const isAfterStartDate = startDate ? constantDate.isSameOrAfter(moment(startDate)) : true; 
                const isBeforeEndDate = endDate ? constantDate.isSameOrBefore(moment(endDate)) : true;
                return isAfterStartDate && isBeforeEndDate && (constantType === "Все" || item.method_display === constantType);
            });
            // const filteredData = fetchedData.filter((item) => {
                //     const constantDate = moment(item.date);
                //     const isAfterStartDate = startDate ? constantDate.isSameOrAfter(moment(startDate), 'day') : true;
                //     const isBeforeEndDate = endDate ? constantDate.isSameOrBefore(moment(endDate), 'day') : true;
                //     return isAfterStartDate && isBeforeEndDate && (constantType === "Все" || item.method_display === constantType);
                // });
            const income = filteredData
                .filter(item => item.method_display === "Доход")
                .reduce((total, item) => total + parseFloat(item.amount), 0);

            const expense = filteredData
                .filter(item => item.method_display === "Расход")
                .reduce((total, item) => total + parseFloat(item.amount), 0);

            setData(filteredData);
            setIncome(income);
            setExpense(expense);
            setTotal(income - expense);

            setTimeout(() => {
                setIsLoading(false);
            }, 800);

        } catch (error) {
            console.error("Error fetching product details:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            } else {
                toast.error("Ошибка при загрузке деталей продукта.");
            }
        }
    };

    const deleteConstant = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_MAIN_URL}/constants/${id}/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            toast.success("Шаблон успешно удалён!");
            navigate('/constant');
        } catch (error) {
            console.error("Ошибка при удалении продукта:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            } else if (error.response && error.response.status === 404) {
                toast.error("Продукт не найден.");
            } else {
                toast.error("Ошибка при удалении продукта.");
            }
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const constantData = {
                name,
            };

            const response = await axios.put(`${process.env.REACT_APP_MAIN_URL}/constants/${id}`, constantData, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            setConstant(response.data);
            toast.success('Шаблон успешно обновлен!');
        } catch (error) {
            console.error('Ошибка при обновлении продукта:', error);
            toast.error('Не удалось обновить продукт. Попробуйте еще раз.');
        }
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const openModal_date = () => setModalDateIsOpen(true);
    const closeModal_date = () => setModalDateIsOpen(false);

    useEffect(() => {
        if (!token) {
            toast.error("Пожалуйста, войдите в систему");
            navigate('/login')
            return;
        }
        fetchConstantRecord();
        fetchConstantDetail();
    }, [id, token, startDate, endDate, constantType]);


    const formatDate = (dateStr) => {
        return moment(dateStr).format('DD.MM.YYYY, HH:mm');
    };


    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />
            <div className={styles.detailContainer}>
                <form onSubmit={handleUpdate} className={styles.updateProductForm}>
                    <h2 className={styles.updateProductFormH2}>Товар</h2>
                    <div className={styles.formGroup}>
                        <label htmlFor="constantName">Название шаблона</label>
                        <input
                            type="text"
                            id="constantName"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className={styles.submitButton}>Сохранить</button>
                    <button type="button" className={styles.submitButton_delete} onClick={openModal}>Удалить</button>
                </form>
                <div className={styles.transaction_list}>
                    <div className={styles.transactionsContainer}>

                        <div className={styles.transaction_showcase_names_border}>
                            <h1 className={styles.transaction_showcase_names_name}>История</h1>
                            <div className={styles.transaction_showcase_names}>
                                <p className={styles.transaction_thename_data} onClick={openModal_date}>Дата</p>
                                <p className={styles.transaction_thename_face}>Тип</p>
                                <p className={styles.transaction_thename_amount}>Сумма</p>
                                <select className={styles.transaction_type_selector} onChange={(e) => setConstantType(e.target.value)}>
                                    <option value="Все">Все</option>
                                    <option value="Расход">Расход</option>
                                    <option value="Доход">Доход</option>
                                </select>
                            </div>
                        </div>


                        <div className={styles.transactions}>
                            {data.map((data, index) => (
                                <div className={styles.transactions_border} key={index}>
                                    <div className={styles.transaction_showcase_apiData}>
                                        <p className={styles.transaction_name}>{formatDate(data.date)}</p>
                                        <p className={styles.transaction_quantity}>{data.method_display}</p>
                                        <p className={styles.transaction_transaction_type_display}>{data.amount}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className={styles.calculateContainer}>
                        <div className={styles.calculateData}>
                            <p className={styles.calculate}>Доход: <span className={styles.space}>{Math.floor(income.toFixed(2))}</span></p>
                            <p className={styles.calculate}>Расход: <span className={styles.space}>{Math.floor(expense.toFixed(2))}</span></p>
                            <p className={styles.calculate}>Итого: <span className={styles.space}>{(Math.floor(income - expense).toFixed(2))}</span></p>
                        </div>
                    </div>
                </div>
            </div>


            {isModalOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Удалении шаблона</h1>
                        <p className={styles.model_delete_product_text}>Вы уверены что хотите удалить?</p>
                        <div className={styles.model_delete_product}>
                            <button className={styles.closeModalButton} onClick={closeModal}>
                                Отмена
                            </button>
                            <button type="button" className={styles.submitButton_delete} onClick={deleteConstant} >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isModalDateIsOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Фильтование по дате</h1>
                        <form>
                            <div className={styles.dateFilterContainer}>
                                <label className={styles.date_label}>Начальная дата:</label>
                                <input
                                    type="datetime-local"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <label>Конечная дата:</label>
                                <input
                                    type="datetime-local"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                            <button type="button" onClick={closeModal_date} className={styles.applyButton}>Применить</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};
