import React, { useState, useEffect, useRef } from 'react';
import styles from './ProductDetail.module.css';
import axios from 'axios';
import { Header } from '../../components/Header/Header';
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';
import Barcode from 'react-barcode';
import PrintBarcode from '../../components/PrintBarcode/PrintBarcode';

export const ProductDetail = () => {
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const [generateBarcode, setGenerateBarcode] = useState(false);
    const token = localStorage.getItem("token");
    const [name, setName] = useState('');
    const [minQuantity, setMinQuantity] = useState(0);
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [price1, setPrice1] = useState(0);
    const [barcode, setBarcode] = useState('');
    const [averagePrice, setAveragePrice] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [selectedTransactionType, setTransactionType] = useState("Все");
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDateIsOpen, setModalDateIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const componentRef = useRef();

    const is_admin = localStorage.getItem("is_admin");

    if (is_admin === 'false') {
        navigate('/non_administrator');
    }

    const fetchProductTransactions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/transactions/?product=${id}`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setTransactions(response.data);
        } catch (error) {
            console.error("Error fetching transactions:", error);
            toast.error("Ошибка при загрузке транзакций.");
        }
    };

    const fetchProductDetail = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/products/${id}/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            const productData = response.data;
            setProduct(productData);
            setName(productData.name);
            setMinQuantity(productData.min_quantity);
            setPurchasePrice(productData.purchase_price);
            setQuantity(productData.quantity);
            setPrice1(productData.price1);
            setAveragePrice(productData.average_price);
            setBarcode(productData.barcode);
            setTimeout(() => {
                setIsLoading(false);
            }, 800)
        } catch (error) {
            console.error("Error fetching product details:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            } else {
                toast.error("Ошибка при загрузке деталей продукта.");
            }
        }
    };


    const deleteProduct = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_MAIN_URL}/products/${id}/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            toast.success("Продукт успешно удалён!");
            navigate('/showcase');
        } catch (error) {
            console.error("Ошибка при удалении продукта:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            } else if (error.response && error.response.status === 404) {
                toast.error("Продукт не найден.");
            } else {
                toast.error("Ошибка при удалении продукта.");
            }
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const productData = {
                name,
                min_quantity: minQuantity,
                purchase_price: purchasePrice,
                average_price: averagePrice,
                quantity,
                price1,
                barcode,
                generate_barcode: generateBarcode,
            };

            const response = await axios.put(`${process.env.REACT_APP_MAIN_URL}/products/${id}/`, productData, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            setProduct(response.data);
            toast.success('Продукт успешно обновлен!');
            window.location.reload()
        } catch (error) {
            console.error('Ошибка при обновлении продукта:', error);
            toast.error('Не удалось обновить продукт. Попробуйте еще раз.');
        }
    };

    const filteredTransactions = transactions.filter((transaction) => {
        const transactionDate = moment(transaction.date);
        const isAfterStartDate = startDate ? transactionDate.isSameOrAfter(moment(startDate), 'day') : true;
        const isBeforeEndDate = endDate ? transactionDate.isSameOrBefore(moment(endDate), 'day') : true;
        return isAfterStartDate && isBeforeEndDate && (selectedTransactionType === "Все" || transaction.transaction_type_display === selectedTransactionType);
    });

    const handlePrint = () => {
        const printWindow = window.open("", "_blank");
        printWindow.document.write(`
            <html>
                <head>
                    <title>Печать</title>
                    <style>
                        @page {
                            size: 40mm 30mm;
                            margin: 0;
                        }
                        body {
                            margin: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100vh;
                        }
                    </style>
                </head>
                <body>
                    ${componentRef.current.outerHTML}
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };


    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const openModal_date = () => setModalDateIsOpen(true);
    const closeModal_date = () => setModalDateIsOpen(false);

    useEffect(() => {
        if (!token) {
            toast.error("Пожалуйста, войдите в систему");
            navigate('/login')
            return;
        }
        fetchProductDetail();
        fetchProductTransactions();
    }, [id, token]);

    const formatDate = (dateStr) => {
        return moment(dateStr).format('DD.MM.YYYY, HH:mm');
    };


    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />
            <div className={styles.detailProduct}>
                <div className={styles.detailContainer}>
                    <div className={styles.updateProductForm}>
                        <form onSubmit={handleUpdate} >
                            <h2 className={styles.updateProductFormH2}>Товар</h2>
                            <div className={styles.formGroup}>
                                <label htmlFor="productName">Название товара</label>
                                <input
                                    type="text"
                                    id="productName"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="barcode">Штрихкод</label>
                                <div className={styles.generate_barcode}>
                                    <input
                                        type="number"
                                        id="barcode"
                                        value={barcode}
                                        onChange={(e) => setBarcode(e.target.value)}
                                    />

                                    <label className={styles.toggle_switch}>
                                        <input type="checkbox"
                                            checked={generateBarcode}
                                            onChange={(e) => setGenerateBarcode(e.target.checked)}
                                        />
                                        <span className={styles.slider}></span>
                                    </label>
                                </div>

                            </div>

                            <div className={styles.formGroup}>
                                <div className={styles.barcodeWrapper}>
                                    {barcode ? (
                                        <Barcode value={barcode.toString()} className={styles.barcode} />
                                    ) : (
                                        <p className={styles.loadingMessage}>Штрихкод не найден</p>
                                    )}
                                </div>
                            </div>



                            <div className={styles.formGroup}>
                                <label htmlFor="minQuantity">Минимальное количество</label>
                                <input
                                    type="number"
                                    id="minQuantity"
                                    value={minQuantity}
                                    onChange={(e) => setMinQuantity(e.target.value)}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="quantity">Количество</label>
                                <input
                                    type="number"
                                    id="quantity"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="purchasePrice">Цена прихода</label>
                                <input
                                    type="number"
                                    id="purchasePrice"
                                    value={purchasePrice}
                                    onChange={(e) => setPurchasePrice(e.target.value)}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="averagePrice">Средняя цена</label>
                                <input
                                    type="number"
                                    id="averagePrice"
                                    value={averagePrice}
                                    onChange={(e) => setAveragePrice(e.target.value)}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="price1">Цена продажи</label>
                                <input
                                    type="number"
                                    id="price1"
                                    value={price1}
                                    onChange={(e) => setPrice1(e.target.value)}
                                    required
                                />
                            </div>
                            <div className={styles.submitButtons}>
                                <button type="submit" className={styles.submitButton}>Сохранить</button>
                                <button type="button" className={styles.submitButton_delete} onClick={openModal}>Удалить</button>
                            </div>
                        </form>
                        <button type="submit" className={styles.submitButton_barcode} onClick={handlePrint} >Печать</button>


                    </div>

                    <PrintBarcode
                        ref={componentRef}
                        name={name}
                        price={price1}
                        barcode={barcode}
                    />

                    <div className={styles.transactionsContainer}>

                        <div className={styles.transaction_showcase_names_border}>
                            <h1 className={styles.transaction_showcase_names_name}>История</h1>
                            <div className={styles.transaction_showcase_names}>
                                <p className={styles.transaction_thename_data} onClick={openModal_date}>Дата</p>
                                <p className={styles.transaction_thename_face}>Лицо</p>
                                <p className={styles.transaction_thename_quantity}>Количество</p>
                                <p className={styles.transaction_thename_price}>Цена </p>
                                <p className={styles.transaction_thename_amount}>Сумма</p>
                                <select className={styles.transaction_type_selector} onChange={(e) => setTransactionType(e.target.value)}>
                                    <option value="Все">Все</option>
                                    <option value="Продажа">Продажа</option>
                                    <option value="Покупка">Покупка</option>
                                </select>
                            </div>
                        </div>


                        <div className={styles.transactions}>
                            {filteredTransactions.map((transaction) => (
                                <div className={styles.transactions_border} key={transaction.id}>
                                    <div className={styles.transaction_showcase_apiData}>
                                        <p className={styles.transaction_name}>{formatDate(transaction.date)}</p>
                                        <p className={styles.transaction_person_name}>
                                            {transaction.person ? transaction.person.name : 'Неизвестно'}
                                        </p>
                                        <p className={styles.transaction_quantity}>{transaction.quantity}</p>
                                        <p className={styles.transaction_price}>{transaction.price}</p>
                                        <p className={styles.transaction_amount}>{transaction.amount}</p>
                                        <p className={styles.transaction_transaction_type_display}>{transaction.transaction_type_display}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>

            {isModalOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Удалении товара</h1>
                        <p className={styles.model_delete_product_text}>Вы уверены что хотите удалить?</p>
                        <div className={styles.model_delete_product}>
                            <button className={styles.closeModalButton} onClick={closeModal}>
                                Отмена
                            </button>
                            <button type="button" className={styles.submitButton_delete} onClick={deleteProduct} >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isModalDateIsOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h1 className={styles.model_delete_product_h1}>Фильтование по дате</h1>
                        <form>
                            <div className={styles.dateFilterContainer}>
                                <label className={styles.date_label}>Начальная дата:</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                                <label>Конечная дата:</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                            <button type="button" onClick={closeModal_date} className={styles.applyButton}>Применить</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};
