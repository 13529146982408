import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Fuse from 'fuse.js';
import moment from 'moment';
import styles from '../SalePage/SalePage.module.css';
import { IoCloseSharp } from "react-icons/io5";
import { toast } from 'react-toastify';


export const Refund = ({ date, onClick }) => {
    const token = localStorage.getItem('token');
    const [products, setProducts] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(0);
    const [total, setTotal] = useState(0);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [addedProducts, setAddedProducts] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cashAmount, setCashAmount] = useState(0);
    const [cashlessAmount, setCashlessAmount] = useState('0');
    const [debtAmount, setDebtAmount] = useState('0');
    const [paidAmount, setPaidAmount] = useState('0');
    const [changeAmount, setChangeAmount] = useState(0);


    useEffect(() => {
        const savedProducts = localStorage.getItem('addedProducts');
        if (savedProducts) {
            setAddedProducts(JSON.parse(savedProducts));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('addedProducts', JSON.stringify(addedProducts));
    }, [addedProducts]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            localStorage.removeItem('addedProducts');
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    const fetchPersonList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/persons/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            const filteredPersons = response.data.filter(person => person.type_p === 's');
            setClients(filteredPersons);
        } catch (error) {
            console.error("Error fetching data:", error);
            if (error.response && error.response.status === 401) {
                console.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            }
        }
    };

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/products/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setProducts(response.data);
        } catch (error) {
            console.error('Ошибка при загрузке данных:', error);
        }
    };

    useEffect(() => {
        fetchPersonList();
        fetchProducts();
    }, [token]);

    const handleProductChange = (event) => {
        const productId = event.target.value;
        const product = products.find(p => p.id === parseInt(productId));

        setSelectedProduct(product || null);
        setQuantity(1);
        setPrice(product ? product.price1 : 0);
        setIsDropdownVisible(false);
        setSearchTerm(product ? product.name : '');
    };

    const handlePriceChange = (event) => {
        const newPrice = parseFloat(event.target.value);
        setPrice(newPrice);
    };

    const handleQuantityChange = (event) => {
        const newQuantity = parseInt(event.target.value);
        setQuantity(newQuantity);
    };

    useEffect(() => {
        setTotal(Math.round(price * quantity));
    }, [price, quantity]);

    const fuse = new Fuse(products, {
        keys: ['name', 'barcode'],
        threshold: 0.4,
    });

    const filteredProducts = searchTerm
        ? fuse.search(searchTerm).map(result => result.item)
        : products;

    const toggleDropdown = () => {
        if (filteredProducts.length > 0) {
            setIsDropdownVisible(prev => !prev);
        }
    };

    const handleAddProduct = () => {
        if (selectedProduct) {
            const newProduct = {
                ...selectedProduct,
                quantity,
                price,
                total,
            };

            setAddedProducts(prev => [...prev, newProduct]);

            setSelectedProduct(null);
            setQuantity(1);
            setPrice(0);
            setSearchTerm('');
            setIsDropdownVisible(false);
        }
    };

    const handleRemoveProduct = (index) => {
        setAddedProducts(prev => prev.filter((_, i) => i !== index));
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && filteredProducts.length > 0) {
            const firstProduct = filteredProducts[0];
            setSelectedProduct(firstProduct);
            setQuantity(1);
            setPrice(firstProduct.price1);
            setSearchTerm(firstProduct.name);
            setIsDropdownVisible(false);
        }
    };

    const openModal = () => {
        setCashAmount(Math.round(totalSum));
        setCashlessAmount('0');
        setDebtAmount('0');
        setPaidAmount('0');
        setChangeAmount(0);
        setIsModalOpen(true);
    };

    const closeModal = () => setIsModalOpen(false);


    const totalQuantity = addedProducts.reduce((acc, product) => acc + product.quantity, 0);
    const totalSum = addedProducts.reduce((acc, product) => acc + product.price * product.quantity, 0);

    const handleCashChange = (e) => {
        const newCashAmount = parseFloat(e.target.value) || 0;
        setCashAmount(newCashAmount);
        const totalPayment = totalSum - newCashAmount - (parseFloat(debtAmount) || 0);
        setCashlessAmount(Math.max(totalPayment, 0).toString());
    };

    const handleCashlessChange = (e) => {
        const newCashlessAmount = parseFloat(e.target.value);
        setCashlessAmount(newCashlessAmount);
        const totalPayment = totalSum - newCashlessAmount - (parseFloat(debtAmount) || 0);
        setCashAmount(Math.max(totalPayment, 0));
    };

    useEffect(() => {
        const totalPayment = (parseFloat(cashlessAmount) || 0) + (parseFloat(debtAmount) || 0);
        const availableCash = totalSum - totalPayment;
        setCashAmount(Math.max(availableCash, 0));
    }, [cashlessAmount, debtAmount, totalSum]);


    const handleSubmit = async () => {
        const saleData = {
            products: addedProducts.map(product => ({
                id: String(product.id),
                quantity: Number(product.quantity),
                price: Number(product.price)
            })),
            cash: String(cashAmount),
            debt: String(debtAmount),
            debtor: null,
            non_cash: String(cashlessAmount),
            published: moment(date).format('MM/DD/YYYY'),
            type: 'refund'
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/sale-buy/`, saleData, {
                headers: {
                    Authorization: `token ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            toast.success('Товар успешно куплен !!!')
            setTimeout(() => {
                window.location.reload();
            }, 1000)
        } catch (error) {
            toast.error('Ошибка при отправке данных:', error);
        }
    };


    return (
        <div className={styles.Main_Sale}>
            <div className={styles.SalePage_Container}>
                <div className={styles.salePage}>
                    <div className={styles.sale_header}>
                        <h1 className={styles.sale__title}>Возврат</h1>
                        <button onClick={onClick} className={styles.exit}><IoCloseSharp /></button>
                    </div>
                    <div className={styles.sale__formFill}>
                        <form onSubmit={(e) => e.preventDefault()} className={styles.sale__form}>
                            <div className={styles.sale__block}>
                                <label>Наименование</label>
                                <div className={styles.sale__choice}>
                                    <input
                                        type="text"
                                        className={styles.inT}
                                        value={searchTerm}
                                        onClick={toggleDropdown}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                            setIsDropdownVisible(true);
                                            setSelectedProduct(null);
                                        }}
                                        onKeyDown={handleKeyDown} // Отслеживаем нажатие Enter
                                        placeholder="Поиск товаров..."
                                    />
                                    {isDropdownVisible && (
                                        <select
                                            className={styles.select}
                                            onChange={handleProductChange}
                                            size={Math.max(filteredProducts.length, 2)}
                                        >
                                            {filteredProducts.length > 0 ? (
                                                <>
                                                    {filteredProducts.length > 1 && <option value="">Выберите продукт</option>}
                                                    {filteredProducts.map((product) => (
                                                        <option key={product.id} value={product.id}>
                                                            {product.name}
                                                        </option>
                                                    ))}
                                                </>
                                            ) : (
                                                <option value="" className={styles.option_warning}>
                                                    Продукты не найдены
                                                </option>
                                            )}
                                        </select>
                                    )}
                                </div>

                            </div>
                            <div className={styles.sale__block}>
                                <label>Кол-во на складе</label>
                                <input
                                    type="text"
                                    className={`${styles.int} ${styles.readOnly}`}
                                    value={selectedProduct ? selectedProduct.quantity : ''}
                                    readOnly
                                />
                            </div>
                            <div className={styles.sale__block}>
                                <label>Сумма</label>
                                <input
                                    type="text"
                                    className={`${styles.int} ${styles.readOnly}`}
                                    value={total}
                                    readOnly
                                />
                            </div>
                            <div className={styles.sale__block}>
                                <label>Цена</label>
                                <input
                                    type="number"
                                    className={styles.inT}
                                    value={Math.round(price)}
                                    onChange={handlePriceChange}
                                    min="0"
                                />
                            </div>
                            <div className={styles.sale__block}>
                                <label>Кол-во</label>
                                <input
                                    type="number"
                                    className={styles.int}
                                    value={quantity}
                                    onChange={handleQuantityChange}
                                    min="1"
                                />
                            </div>
                            <div className={styles.sale__block}>
                                <button type="submit" className={styles.coming__btn} onClick={handleAddProduct}>Добавить</button>
                            </div>
                        </form>
                    </div>
                    <div className={styles.products_sale_names}>
                        <p className={styles.product_thename}>Наименовании</p>
                        <p className={styles.product_thename}>Цена</p>
                        <p className={styles.product_thename}>Кол-во</p>
                        <p className={styles.product_thename}>Сумма</p>
                    </div>
                    <div className={styles.product__list_container}>
                        <div className={styles.product_list}>
                            {addedProducts.map((product, index) => (
                                <div key={index} className={styles.product_item}>
                                    <p>{product.name}</p>
                                    <input
                                        type="number"
                                        value={Math.round(product.price)}
                                        onChange={(e) => {
                                            const updatedProducts = [...addedProducts];
                                            updatedProducts[index].price = parseFloat(e.target.value);
                                            setAddedProducts(updatedProducts);
                                        }}
                                        className={styles.product__int}
                                        min="0"
                                    />
                                    <input
                                        type="number"
                                        value={product.quantity}
                                        onChange={(e) => {
                                            const updatedProducts = [...addedProducts];
                                            updatedProducts[index].quantity = parseInt(e.target.value);
                                            setAddedProducts(updatedProducts);
                                        }}
                                        className={styles.product__int}
                                        min="1"
                                    />
                                    <input
                                        type="text"
                                        value={Math.round(product.price * product.quantity)}
                                        readOnly
                                        className={`${styles.product__int} ${styles.readOnly}`}
                                    />
                                    <IoCloseSharp
                                        className={styles.closeBtn}
                                        onClick={() => handleRemoveProduct(index)}
                                    />
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className={styles.products_sale_names}>
                        <p className={styles.product_thename}>Общее кол-во :</p>
                        <p className={styles.product_thename}>{totalQuantity}</p>
                        <p className={styles.product_thename}>Общая сумма :</p>
                        <p className={styles.product_thename}>{Math.round(totalSum)}</p>
                        <button className={styles.btnSub} onClick={openModal}>Провести</button>
                    </div>
                </div>

                {isModalOpen && (
                    <div className={styles.modalOverlay}>
                        <div className={styles.modalContent}>
                            <h2 className={styles.Spend__subtitle}>Провести</h2>
                            <form className={styles.form}>
                                <div className={styles.calculation}>
                                    <div className={styles.from_control}>
                                        <label className={styles.label}>Наличные</label>
                                        <input
                                            type="number"
                                            value={cashAmount}
                                            onChange={handleCashChange} // Изменено
                                        />
                                    </div>

                                    <div className={styles.from_control}>
                                        <label className={styles.label}>Безналичные</label>
                                        <input
                                            type="number"
                                            value={cashlessAmount}
                                            onChange={handleCashlessChange} // Изменено
                                            placeholder="Введите сумму безналом"
                                        />
                                    </div>
                                </div>
                            </form>
                            <div className={styles.btns}>
                                <button onClick={closeModal} className={`${styles.modalBtn} ${styles.closeModal}`}>Закрыть</button>
                                <button onClick={handleSubmit} className={`${styles.modalBtn} ${styles.pushBtn}`}>Провести</button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};