import React, { useEffect, useState } from 'react';
import styles from './Showcase.module.css';
import axios from 'axios';
import Fuse from 'fuse.js';
import { toast } from "react-toastify";
import { Header } from '../../components/Header/Header';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';

export const Showcase = () => {
    const [products, setProducts] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [generate_barcode, setGenerateBarcode] = useState(false)
    const [minQuantity, setMinQuantity] = useState(0);
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [barcode, setBarcode] = useState('');
    const [price1, setPrice1] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isMinQuantityFilterActive, setIsMinQuantityFilterActive] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const navigate = useNavigate()

    const is_admin = localStorage.getItem("is_admin");
    const token = localStorage.getItem("token");
    if (!token) {
        toast.error("Пожалуйста, войдите в систему");
        navigate('/login')
    }
    
    if (is_admin === 'false') {
        navigate('/non_administrator');
    }

    const fetchProductList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/products/`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setProducts(response.data);
            setTimeout(() => {
                setIsLoading(false);
            }, 800);
        } catch (error) {
            console.error("Error fetching data:", error);
            if (error.response && error.response.status === 401) {
                toast.error("Ошибка авторизации. Пожалуйста, войдите в систему снова.");
            }
        }
    };

    const fetchProductCreate = async () => {
        try {
            const productData = {
                name,
                barcode,
                min_quantity: minQuantity,
                purchase_price: purchasePrice,
                average_price: purchasePrice,
                generate_barcode: generate_barcode,
                quantity,
                price1
            };

            const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/products/`, productData, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            const createdProduct = response.data;
            toast.success('Продукт успешно создан!');
            return createdProduct;

            closeModal();
        } catch (error) {
            console.error('Ошибка при создании продукта:', error);
            toast.error('Не удалось создать продукт. Попробуйте еще раз.');
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const newProduct = await fetchProductCreate();

        if (newProduct) {
            setProducts((prevProducts) => [...prevProducts, newProduct]);

            setName('');
            setBarcode('');
            setGenerateBarcode(false)
            setMinQuantity(0);
            setPurchasePrice(0);
            setQuantity(0);
            setPrice1(0);

            closeModal();
        }
    };

    const handleProductClick = (id) => {
        navigate(`/product/${id}`);
    };

    const fuse = new Fuse(products, {
        keys: ['name', 'barcode'],
        threshold: 0.4,
    });

    const filteredProducts = (searchTerm
        ? fuse.search(searchTerm).map(result => result.item)
        : products
    ).filter(product => {
        if (isMinQuantityFilterActive) {
            return product.quantity <= product.min_quantity;
        }
        return true;
    });

    const handleProductChange = (event) => {
        const productId = event.target.value;
        const product = products.find(p => p.id === parseInt(productId));

        setIsDropdownVisible(false);
        setSearchTerm(product ? product.name : '');
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
        setIsDropdownVisible(true);
    };

    const toggleDropdown = () => {
        if (filteredProducts.length > 0) {
            setIsDropdownVisible(prev => !prev);
        }
    };

    useEffect(() => {

        const fetchAndCalculate = async () => {
            await fetchProductList(); 
    
            setProducts(prevProducts => {
                let totalQty = 0;
                let totalSum = 0;
    
                prevProducts.forEach(product => {
                    totalQty += product.quantity;
                    totalSum += product.quantity * product.purchase_price;
                });
    
                setTotalQuantity(totalQty);
                setTotalPrice(totalSum);
    
                return prevProducts;
            });
        };
    
        fetchAndCalculate();
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />
            <div className={styles.product_block}>
                <div className={styles.product_container}>
                    <div>
                        <h1 className={styles.product_title}>Товары</h1>
                    </div>

                    <div className={styles.products_showcase_data}>
                        <div className={styles.product_report_border}>
                            <div className={styles.product_report_block}>

                                <div className={styles.product_report}>
                                    <p className={styles.total_data}>Общее количество товаров: {totalQuantity}</p>
                                    <p className={styles.total_data}>Товаров на сумму: {totalPrice.toFixed(2)}</p>
                                    <div>
                                        <button className={styles.btn_product_create} onClick={openModal}> Создать товар</button>
                                    </div>
                                </div>

                                <div className={styles.product_search}>

                                    <input
                                        type="text"
                                        placeholder="Поиск"
                                        className={styles.search_input}
                                        value={searchTerm}
                                        onChange={handleSearchInputChange}
                                        onFocus={toggleDropdown}
                                    />
                                    <input
                                        type="checkbox"
                                        className={styles.search_checkbox}
                                        checked={isMinQuantityFilterActive}
                                        onChange={(e) => setIsMinQuantityFilterActive(e.target.checked)}
                                    />

                                    <label htmlFor="" className={styles.serach_label}>Мин</label>
                                    <button className={styles.product_search_btn}>Поиск</button>
                                </div>

                            </div>
                        </div>

                        <div className={styles.products_showcase_names}>
                            <p className={styles.product_thename}>Наименовании</p>
                            <p className={styles.product_thename}>Количество</p>
                            <p className={styles.product_thename}>Минимальное количество</p>
                            <p className={styles.product_thename}>Цена Прихода</p>
                        </div>

                        <div className={styles.product_list}>
                            {filteredProducts.map((product) => (

                                <div key={product.id} onClick={() => handleProductClick(product.id)} className={styles.products_showcase_apiData}>
                                    <p className={styles.products_name}>{product.name}</p>
                                    <p className={styles.products_quantity}>{product.quantity}</p>
                                    <p className={styles.products_min}>{product.min_quantity}</p>
                                    <p className={styles.products_purchase_price}>{product.purchase_price}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h2 className={styles.title_product_create_form}>Создание товара</h2>
                        <form onSubmit={handleFormSubmit}>
                            <div className={styles.formGroup}>
                                <label htmlFor="productName" className={styles.create_product_label}>Название товара</label>
                                <input
                                    type="text"
                                    id="productName"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Введите название товара"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="productName" className={styles.create_product_label}>Штрихкод</label>
                                <div className={styles.barode_div}>
                                    <div className={styles.int_cont}>
                                        <input
                                            type="text"
                                            id="productName"
                                            value={barcode}
                                            onChange={(e) => setBarcode(e.target.value)}
                                            placeholder="Введите штрихкод товара"
                                            className={styles.create_product_input}
                                        />
                                    </div>
                                    <div className={styles.int_cont}>
                                    <input
                                        type="checkbox"
                                        id="productName"
                                        value={generate_barcode}
                                        onChange={(e) => setGenerateBarcode(!generate_barcode)}
                                        className={styles.create_barcode_input}
                                    />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="minQuantity" className={styles.create_product_label}>Минимальное количество</label>
                                <input
                                    type="number"
                                    id="minQuantity"
                                    value={minQuantity}
                                    onChange={(e) => setMinQuantity(e.target.value)}
                                    placeholder="Введите минимальное количество"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="quantity" className={styles.create_product_label}>Количество</label>
                                <input
                                    type="number"
                                    id="quantity"
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    placeholder="Введите количество"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="purchasePrice" className={styles.create_product_label}>Цена прихода</label>
                                <input
                                    type="number"
                                    id="purchasePrice"
                                    value={purchasePrice}
                                    onChange={(e) => setPurchasePrice(e.target.value)}
                                    placeholder="Введите цену прихода"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>

                            <div className={styles.formGroup}>
                                <label htmlFor="price1" className={styles.create_product_label}>Цена</label>
                                <input
                                    type="number"
                                    id="price1"
                                    value={price1}
                                    onChange={(e) => setPrice1(e.target.value)}
                                    placeholder="Введите цену"
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>
                            <button type="submit" className={styles.submitButton}>
                                Создать
                            </button>
                        </form>
                        <button className={styles.closeModalButton} onClick={closeModal}>
                            Закрыть
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

