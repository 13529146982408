import React, { useState, useEffect } from 'react';
import styles from './AdminSite.module.css';
import { Header } from '../../components/Header/Header';
import { Loader } from '../../components/Loader/Loader';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const AdminSite = () => {
    const [isLoading, setIsLoading] = useState(true);
    const token = localStorage.getItem("token");
    const [finance, setFinance] = useState({});
    const [isModalOpenNonCash, setIsModalOpenNonCash] = useState(false);
    const [isModalOpenCash, setIsModalOpenCash] = useState(false);
    const [cash, setCash] = useState(0);
    const [non_cash, setNonCash] = useState(0);
    const [financeId, setFinanceId] = useState(null);
    const navigate = useNavigate()

    const is_admin = localStorage.getItem("is_admin");

    if (is_admin === 'false') {
        navigate('/non_administrator');
    }

    const fetchFinanceList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_MAIN_URL}/finances/calc-finance`, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setFinance(response.data);
            setCash(response.data.cash);
        } catch (error) {
            console.error("Error fetching transactions:", error);
            toast.error("Ошибка при загрузке транзакций.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateNonCash = async (e) => {
        e.preventDefault();

        const updatedNonCash = Number(non_cash);
        const updatedCash = total_cash - updatedNonCash;

        try {
            const financesData = {
                cash: updatedCash,
                non_cash: updatedNonCash,
            };

            const response = await axios.put(`${process.env.REACT_APP_MAIN_URL}/finances/${financeId}/`, financesData, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });
            setFinance(response.data);
            setIsModalOpenNonCash(false);
            toast.success('Финансы успешно обновлены!');
        } catch (error) {
            console.error('Ошибка при обновлении финансов:', error);
            if (error.response) {
                console.error('Детали ошибки:', error.response.data);
            }
            toast.error('Не удалось обновить финансы. Попробуйте еще раз.');
        }
    };

    const handleUpdateCash = async (e) => {
        e.preventDefault();

        try {
            const financesData = {
                cash,
            };

            const response = await axios.put(`${process.env.REACT_APP_MAIN_URL}/finances/${financeId}/`, financesData, {
                headers: {
                    Authorization: `token ${token}`,
                },
            });

            setFinance(response.data);
            setIsModalOpenCash(false);
            toast.success('Финансы успешно обновлены!');
        } catch (error) {
            console.error('Ошибка при обновлении финансов:', error);
            if (error.response) {
                console.error('Детали ошибки:', error.response.data);
            }
            toast.error('Не удалось обновить финансы. Попробуйте еще раз.');
        }
    };

    const hundleConstantClick = () => {
        navigate(`/constant`);
    };

    const hundleFinanceReportClick = () => {
        navigate(`/financeReport`);
    };

    const hundleEmployeeClick = () => {
        navigate(`/employee`);
    };

    const hundleKassaClick = () => {
        navigate(`/kassa`);
    };

    const hundleDailyReportClick = () => {
        navigate(`/dailyReport`);
    };

    useEffect(() => {
        if (!token) {
            toast.error("Пожалуйста, войдите в систему");
            navigate('/login')
            return;
        }
        fetchFinanceList();
    }, [token]);

    if (isLoading) {
        return <Loader />;
    }

    const openModalNonCash = (financeId) => {
        setIsModalOpenNonCash(true);
        setNonCash(finance.non_cash);
        setFinanceId(financeId);
    };
    const closeModalNonCash = () => setIsModalOpenNonCash(false);

    const openModalCash = (financeId) => {
        setIsModalOpenCash(true);
        setCash(finance.cash);
        setFinanceId(financeId);
    };
    const closeModalCash = () => setIsModalOpenCash(false);

    const total_cash = Number(finance.cash) + Number(finance.non_cash);

    return (
        <div>
            <Header />
            <div className={styles.name_page}>
                <h1>Админ панель</h1>
            </div>
            <div className={styles.container}>
                <div className={styles.container_cash_result}>
                    <div className={styles.cash_result}>
                        <p className={styles.finance_data_result}>Касса: <span className={styles.large_space}>{total_cash}</span></p>
                    </div>
                    <div className={styles.cash_result}>
                        <p className={styles.finance_data_result}>Товаров на сумму: <span className={styles.large_space}>{finance.turnover}</span></p>
                    </div>
                </div>
                <div className={styles.container_data_cash}>
                    <div className={styles.financeContainer} onClick={() => openModalCash(finance.id)}>
                        <p className={styles.finance_data}>Наличные</p>
                        <p className={styles.finance_data}>{finance.cash}</p>
                    </div>
                    <div className={styles.financeContainer} onClick={() => openModalNonCash(finance.id)}>
                        <p className={styles.finance_data}>Безналичные</p>
                        <p className={styles.finance_data}>{finance.non_cash}</p>
                    </div>
                </div>
            </div>

            <div className={styles.navigators}>
                <h2 className={styles.navigator} onClick={hundleConstantClick}>Шаблон</h2>
                <h2 className={styles.navigator} onClick={hundleDailyReportClick}>Дневной отчет</h2>
                <h2 className={styles.navigator} onClick={hundleKassaClick}>Касса</h2>
                <h2 className={styles.navigator} onClick={hundleFinanceReportClick}>Финансы</h2>
                <h2 className={styles.navigator} onClick={hundleEmployeeClick}>Сотрудник</h2>
            </div>

            {isModalOpenNonCash && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h2 className={styles.title_product_create_form}>Обновление данных</h2>
                        <form onSubmit={handleUpdateNonCash}>
                            <div className={styles.formGroup}>
                                <label htmlFor="non_cash" className={styles.create_product_label}>Безналичные</label>
                                <input
                                    type="number"
                                    id="non_cash"
                                    value={non_cash}
                                    onChange={(e) => setNonCash(e.target.value)}
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>
                            <button type="submit" className={styles.submitButton}>
                                Обновить
                            </button>
                        </form>
                        <button className={styles.closeModalButton} onClick={closeModalNonCash}>
                            Закрыть
                        </button>
                    </div>
                </div>
            )}

            {isModalOpenCash && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <h2 className={styles.title_product_create_form}>Обновление данных</h2>
                        <form onSubmit={handleUpdateCash}>
                            <div className={styles.formGroup}>
                                <label htmlFor="cash" className={styles.create_product_label}>Наличные</label>
                                <input
                                    type="number"
                                    id="cash"
                                    value={cash}
                                    onChange={(e) => setCash(e.target.value)}
                                    className={styles.create_product_input}
                                    required
                                />
                            </div>
                            <button type="submit" className={styles.submitButton}>
                                Обновить
                            </button>
                        </form>
                        <button className={styles.closeModalButton} onClick={closeModalCash}>
                            Закрыть
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
