import React, { useState } from 'react';
import styles from './Header.module.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";


export const Header = () => {
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [shop, setShop] = useState(localStorage.getItem("shop_id"));
    const [is_admin, setIs_admin] = useState(localStorage.getItem("is_admin"));
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Состояние для бургер-меню
    const navigate = useNavigate();

    // const logout = async () => {
    //     try {
    //         await axios.post(`${process.env.REACT_APP_MAIN_URL}/logout/`, {}, {
    //             headers: {
    //                 Authorization: `token ${token}`
    //             }
    //         });

    //         localStorage.removeItem("token");
    //         setToken(null);

    //         navigate("/login");
    //     } catch (error) {
    //         console.error("Ошибка при логауте:", error);
    //     }
    // };

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("shop_id");
        localStorage.removeItem("is_admin");
        setToken(null);
        setShop(null);
        setIs_admin(null);
        navigate("/login");
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    if (is_admin === 'false') {
        return (
            <div className={styles.header}>
                <div className={styles.header_block}>
                    <button className={styles.burger} onClick={toggleMenu}>
                        {isMenuOpen ? <IoMdClose className={styles.closeIcon} /> : <RxHamburgerMenu className={styles.burgerMenu} />}
                    </button>
                    <div className={`${styles.header_navBlock_non} ${isMenuOpen ? styles.open : ''}`}>
                        <Link to={'/non_administrator'} className={styles.header_nav}>Главная</Link>
                        <Link to={'/customer'} className={styles.header_nav}>Клиенты</Link>
                    </div>
                    <div className={styles.header_logout}>
                        <button className={styles.header_a} onClick={logout}>Выйти</button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.header}>
            <div className={styles.header_block}>
                <button className={styles.burger} onClick={toggleMenu}>
                    {isMenuOpen ? <IoMdClose className={styles.closeIcon} /> : <RxHamburgerMenu className={styles.burgerMenu} />}
                </button>
                <div className={`${styles.header_navBlock} ${isMenuOpen ? styles.open : ''}`}>
                    <Link to={'/'} className={styles.header_nav}>Главная</Link>
                    <Link to={'/showcase'} className={styles.header_nav}>Товары</Link>
                    <Link to={'/customer'} className={styles.header_nav}>Клиенты</Link>
                    <Link to={'/supplier'} className={styles.header_nav}>Поставщики</Link>
                    <Link to={'/adminsite'} className={styles.header_nav}>Админ панель</Link>
                </div>
                <div className={styles.header_logout}>
                    <button className={styles.header_a} onClick={logout}>Выйти</button>
                </div>
            </div>
        </div>
    );
};